import { loadingController, toastController } from "@ionic/core";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  withIonLifeCycle,
} from "@ionic/react";
import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import LimeAPI from "../classes/LimeAPI";
import Header from "../components/Header/Header";
import "../components/QualityDocuments/quality-documents.css";
import QualityDocumentSectionFive from "../components/QualityDocuments/QualityDocumentSectionFive";
import QualityDocumentSectionFour from "../components/QualityDocuments/QualityDocumentSectionFour";
import QualityDocumentSectionOne from "../components/QualityDocuments/QualityDocumentSectionOne";
import QualityDocumentSectionThree from "../components/QualityDocuments/QualityDocumentSectionThree";
import QualityDocumentSectionTwo from "../components/QualityDocuments/QualityDocumentSectionTwo";
import QualityDocumentsHeader from "../components/QualityDocuments/QualityDocumentsHeader";
import { logEvent } from "../helpers/analytics";
import { setProjectStatus } from "../helpers/project";
import { currentUserIsAdmin } from "../helpers/roles";
import { newQualityDocumentState } from "../helpers/states";
import { validateForm } from "../helpers/validation";
import { actions, RootState } from "../store/index";
import { QualityDocumentsInterface } from "../store/qualityDocuments/types";
import { APIUserInterface } from "../store/user/actions";

class PageNewQualityDocument extends React.Component<any, any> {
  abortController = new AbortController();
  supplierAbortController = new AbortController();

  constructor(props: any) {
    super(props);

    this.state = newQualityDocumentState();

    this.onInputChange = this.onInputChange.bind(this);
    this.onAddPicture = this.onAddPicture.bind(this);
    this.removePicture = this.removePicture.bind(this);
    this.createQualityDocument = this.createQualityDocument.bind(this);
  }

  ionViewWillEnter() {
    const { user, project } = this.props;

    this.getSuppliers();

    this.setState(
      {
        ...this.state,
        formData: {
          ...this.state.formData,
          authorizationno: {
            required: this.state.formData.authorizationno.required,
            value: user.company.qualificationNo,
          },
          company: {
            required: this.state.formData.company.required,
            value: user.company.id,
          },
          companyname: {
            required: this.state.formData.companyname.required,
            value: user.company.name,
          },
          objectname: {
            required: this.state.formData.objectname.required,
            value: project.name,
          },
          project: {
            required: this.state.formData.project.required,
            value: project.id,
          },
          registrationno: {
            required: this.state.formData.registrationno.required,
            value: user.company.orgNo,
          },
          responsibilityinsurance: {
            required: this.state.formData.registrationno.required,
            value: user.company.responsibilityinsurance_text,
          },
        },
      },
      () => {
        if (currentUserIsAdmin()) {
          return;
        }

        this.setState({
          ...this.state,
          formData: {
            ...this.state.formData,
            persontiler: {
              required: this.state.formData.persontiler.required,
              value: user.id,
            },
          },
        });
      }
    );
  }

  getSuppliers() {
    LimeAPI.get("suppliers", this.supplierAbortController.signal)
      .then((response) => this.setState({ supplier: response.data }))
      .then(() => {
        const companyData = this.props.user.company;
        const wallzone1supplier = this.state.supplier.find(
          (supplier: { suppliername: string }) =>
            companyData.wallzone1supplier_text === supplier.suppliername
        );
        const wallzone2supplier = this.state.supplier.find(
          (supplier: { suppliername: string }) =>
            companyData.wallzone2supplier_text === supplier.suppliername
        );
        const floorsupplier = this.state.supplier.find(
          (supplier: { suppliername: string }) =>
            companyData.floorsupplier_text === supplier.suppliername
        );

        this.setState({
          ...this.state,
          formData: {
            ...this.state.formData,
            companysupplierfloor: {
              ...this.state.formData.companysupplierfloor,
              value: floorsupplier._id + "",
              isTouched: true,
            },
            companysupplierfloor_text: {
              ...this.state.formData.companysupplierfloor_text,
              value: floorsupplier.suppliername,
              isTouched: true,
            },
            companywallzone1supplier: {
              ...this.state.formData.companywallzone1supplier,
              value: wallzone1supplier._id + "",
              isTouched: true,
            },
            companywallzone1supplier_text: {
              ...this.state.formData.companywallzone1supplier_text,
              value: wallzone1supplier.suppliername,
              isTouched: true,
            },
            companywallzone2supplier: {
              ...this.state.formData.companywallzone2supplier,
              value: wallzone2supplier._id + "",
              isTouched: true,
            },
            companywallzone2supplier_text: {
              ...this.state.formData.companywallzone2supplier_text,
              value: wallzone2supplier.suppliername,
              isTouched: true,
            },
          },
        });
      })
      .catch(() => false);
  }

  onInputChange(event: any) {
    this.saveState(event);

    const { name } = event.target;
    const supplierInputs = [
      "companysupplierfloor",
      "companywallzone1supplier",
      "companywallzone2supplier",
    ];

    if (supplierInputs.includes(name)) {
      this.handleSupplierChange(event);
    }
  }

  handleSupplierChange(event: any) {
    const supplierId = event.target.value;
    const target = event.target.name
      .replace("supplier", "")
      .replace("company", "suppliersystem");
    const isFloorSystem = event.target.name.indexOf("floor") > -1;
    const fieldName = event.target.name + "_text";

    const selectedSupplier = this.state.supplier.find(
      (supplier: { _id: number }) => parseInt(supplierId, 10) === supplier._id
    );

    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        [fieldName]: {
          ...this.state.formData[fieldName],
          value: selectedSupplier.suppliername,
        },
      },
    });

    if (!this.state.formData.companysupplierfloor.value) {
      this.setState({
        ...this.state,
        formData: {
          ...this.state.formData,
          companysupplierfloor: {
            ...this.state.formData.companysupplierfloor,
            value: event.target.value,
            isTouched: true,
          },
          companysupplierfloor_text: {
            ...this.state.formData.companysupplierfloor_text,
            value: selectedSupplier.suppliername,
          },
        },
      });
    }

    if (!this.state.formData.companywallzone1supplier.value) {
      this.setState({
        ...this.state,
        formData: {
          ...this.state.formData,
          companywallzone1supplier: {
            ...this.state.formData.companywallzone1supplier,
            value: event.target.value,
            isTouched: true,
          },
          companywallzone1supplier_text: {
            ...this.state.formData.companywallzone1supplier_text,
            value: selectedSupplier.suppliername,
          },
        },
      });
    }

    if (!this.state.formData.companywallzone2supplier.value) {
      this.setState({
        ...this.state,
        formData: {
          ...this.state.formData,
          companywallzone2supplier: {
            ...this.state.formData.companywallzone2supplier,
            value: event.target.value,
            isTouched: true,
          },
          companywallzone2supplier_text: {
            ...this.state.formData.companywallzone2supplier_text,
            value: selectedSupplier.suppliername,
          },
        },
      });
    }

    LimeAPI.get(
      `suppliers/${supplierId}/system`,
      this.supplierAbortController.signal
    )
      .then((response) =>
        this.setState({
          [target]:
            response.data[isFloorSystem ? "floorsuppliers" : "wallsuppliers"],
          formData: {
            ...this.state.formData,
            [target]: {
              ...this.state.formData[target],
              value: undefined,
            },
          },
        })
      )
      .catch(() => false);
  }

  async onAddPicture(picture: any) {
    const pictures = [...this.state.formData.picture.value, picture];
    await this.savePictureState(pictures);
  }

  async removePicture(index: number) {
    const pictures: any = this.state.formData.picture.value;
    const filteredPictures = pictures.filter(
      (picture: any, pictureIndex: number) => pictureIndex !== index
    );

    await this.savePictureState(filteredPictures);
  }

  async resizeImage(base64Str: string, maxWidth = 800, maxHeight = 450) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx: any = canvas.getContext("2d");

        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
    });
  }

  async savePictureState(pictures: any) {
    pictures = await Promise.all(
      pictures.map((picture: string) => this.resizeImage(picture))
    );

    this.setState(
      {
        formData: {
          ...this.state.formData,
          picture: {
            value: pictures,
            required: this.state.formData.picture.required,
          },
        },
      },
      () => this.setState({ isValid: validateForm(this.state) })
    );
  }

  saveState(event: any) {
    const target = event.target;
    const value =
      event.target.localName === "ion-checkbox" ? target.checked : target.value;
    const name = target.name;

    const { formData }: any = this.state;
    const { project }: any = this.props;

    const relations: any = {};

    const inputName = target.getAttribute("name");
    const personFieldsLookup: any = {
      personsupervisor: "wetworkteamlead",
      persontiler: "tilername",
    };

    if (formData[name] && formData[name].relation) {
      for (const key in formData[name].relation) {
        if (formData[name].relation.hasOwnProperty(key)) {
          const relationName = formData[name].relation[key];
          relations[relationName] = {
            ...formData[relationName],
            required: false,
            value: false,
          };
        }
      }
    }

    // refactor this.
    if (inputName === "persontiler") {
      const personField = personFieldsLookup[inputName];

      const personMatch = project.persons.tilers.find(
        (person: any) => person.personId === target.value
      );

      if (typeof personMatch !== "undefined") {
        formData[personField].value = personMatch.name;
      }
    }

    // refactor this.
    if (inputName === "personsupervisor") {
      const personField = personFieldsLookup[inputName];

      const personMatch = project.persons.supervisors.find(
        (person: any) => person.personId === target.value
      );

      if (typeof personMatch !== "undefined") {
        formData[personField].value = personMatch.name;
      }
    }

    this.setState(
      {
        formData: {
          ...this.state.formData,
          [name]: {
            value,
            isTouched: true,
            relation:
              formData[name] && formData[name].relation !== undefined
                ? formData[name].relation
                : undefined,
            required:
              formData[name] && formData[name].required !== undefined
                ? formData[name].required
                : undefined,
          },
          ...relations,
        },
      },
      () => {
        const notesConditionals =
          this.state.formData.floorfoundationok.value === true &&
          this.state.formData.wallfoundationok.value === true &&
          this.state.formData.otherleadthrough.value === false;

        this.setState(
          {
            formData: {
              ...this.state.formData,
              otherinformation: {
                ...this.state.formData.otherinformation,
                required: !notesConditionals,
              },
            },
          },
          () => this.setState({ isValid: validateForm(this.state) })
        );

        this.setState({ isValid: validateForm(this.state) });
      }
    );
  }

  async createQualityDocument() {
    const { formData }: any = this.state;
    const data: any = {};

    loadingController
      .create({
        spinner: "crescent",
        message: "Sparar kvalitetsdokument...",
      })
      .then((controller) => controller.present())
      .catch();

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        data[key] = formData[key].value;
      }
    }

    const response = await LimeAPI.post(
      "qualitydocuments",
      data,
      this.abortController.signal
    );
    loadingController.dismiss().catch();

    if (!response.success) {
      toastController
        .create({
          message: "Projektet kunde inte sparas, försök igen.",
          color: "dark",
          buttons: ["Ok"],
        })
        .then((toast) => toast.present())
        .catch();

      return;
    }

    await logEvent("createQualityDocument", {
      id: response.data._id,
      timestamp: response.data._timestamp,
    });

    this.props.addQualityDocument(response);

    const companyDefaultStates = {
      _embedded: {
        relation_company: {
          wallzone1supplier_text: this.state.formData
            .companywallzone1supplier_text.value,
          wallzone2supplier_text: this.state.formData
            .companywallzone2supplier_text.value,
          floorsupplier_text: this.state.formData.companysupplierfloor_text
            .value,
          responsibilityinsurance_text: this.state.formData
            .responsibilityinsurance.value,
        },
      },
    };

    this.props.setCompanyDefaultStates(companyDefaultStates);

    setProjectStatus("open", "open").catch(() => false);

    toastController
      .create({
        message: `Kvalitetsdokumentet "${response.data.objectname}" har lagts till.`,
        duration: 3000,
        color: "success",
      })
      .then((toast) => toast.present())
      .catch();

    this.setState({ hasSubmitted: true });
  }

  render() {
    if (this.state.hasSubmitted) {
      return <Redirect to={`/projects/${this.props.match.params.id}`} />;
    }

    return (
      <IonPage>
        <Header
          componentLeft="back"
          title="Kvalitetsdokument"
          componentRight="createQualityDocumentButton"
          componentRightHandler={this.createQualityDocument}
          componentSettings={{ isValid: this.state.isValid }}
        />
        <IonContent>
          <form>
            <QualityDocumentsHeader hideStatus={true} />

            <IonGrid className="ion-padding-top ion-padding-bottom">
              <IonRow className="ion-justify-content-center">
                <IonCol sizeSm="10" sizeMd="8" sizeLg="6">
                  <QualityDocumentSectionOne
                    {...this.state}
                    inputChangeCallback={this.onInputChange}
                  />
                  <QualityDocumentSectionTwo
                    {...this.state}
                    inputChangeCallback={this.onInputChange}
                  />
                  <QualityDocumentSectionThree
                    {...this.state}
                    inputChangeCallback={this.onInputChange}
                    addPicture={this.onAddPicture}
                    removePicture={this.removePicture}
                  />
                  <QualityDocumentSectionFour
                    {...this.state}
                    inputChangeCallback={this.onInputChange}
                  />
                  <QualityDocumentSectionFive
                    {...this.state}
                    inputChangeCallback={this.onInputChange}
                  />

                  <IonButton
                    expand="block"
                    onClick={() => this.createQualityDocument()}
                    disabled={!this.state.isValid}
                    color={this.state.isValid ? "success" : "medium"}
                  >
                    Skapa kvalitetsdokument
                  </IonButton>
                  {!this.state.isValid && (
                    <p className="form-description font-size-xs">
                      För att kunna skapa kvalitetsdokumentet behöver alla
                      obligatoriska fält vara ifyllda.
                    </p>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    project: state.currentProject,
    user: state.user,
  };
};

const mapDispatchToProps = {
  addQualityDocument: (document: QualityDocumentsInterface) =>
    actions.qualityDocuments.addQualityDocument(document),
  setCompanyDefaultStates: (user: APIUserInterface) =>
    actions.user.setCompanyDefaultStates(user),
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withIonLifeCycle(PageNewQualityDocument))
);

// const PageWithLifeCycle = withIonLifeCycle(PageNewQualityDocument);
// const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(PageWithLifeCycle);
// const PageWithRouter = withRouter(ConnectedPage);

// export default PageWithRouter;
