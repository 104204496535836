import React from "react";
import {
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
} from "@ionic/react";
import store from "../../store";
import { currentUserIsAdmin } from "../../helpers/roles";

class QualityDocumentSectionFour extends React.Component<any> {
  constructor(props: any) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event: any) {
    this.props.inputChangeCallback(event);
  }

  render() {
    const { formData } = this.props;
    const { currentProject, user } = store.getState();
    let { supervisors, tilers } = currentProject.persons;
    const numbers = new Array(99).fill(1);
    supervisors = (supervisors || []);
    tilers = (tilers || []);

    const tilersMarkup = [];

    const combos = (supervisors || []).filter(
      (supervisor: any) => supervisor.position.key === "combo"
    );

    const uniqueIds: any[] = [];

    const tilersAndCombo = ([...tilers, ...combos] || []).filter(
      (user: any) => {
        if (uniqueIds.includes(user.personId)) {
          return false;
        }

        uniqueIds.push(user.personId);

        return true;
      }
    );

    if (currentUserIsAdmin()) {
      (tilersAndCombo || []).forEach((tiler: any) => {
        tilersMarkup.push(
          <IonSelectOption
            key={`tiler-${parseInt(tiler._id, 10)}`}
            value={parseInt(tiler.personId, 10)}
          >
            {tiler.name}
          </IonSelectOption>
        );
      });
    } else {
      tilersMarkup.push(
        <IonSelectOption key={user.id} value={user.id} selected={true}>
          {user.name}
        </IonSelectOption>
      );
    }

    return (
      <IonItemGroup className="form-section">
        <IonItemDivider sticky={true} mode="ios">
          Behörig personal
        </IonItemDivider>

        <div className="form-group has-select">
          <IonLabel position="stacked">
            Välj plattsättare som applicerat tätskiktet
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </IonLabel>
          <IonSelect
            name="persontiler"
            cancelText="Avbryt"
            okText="Lägg till"
            placeholder="Lägg till plattsättare"
            onIonChange={this.onInputChange}
            value={formData.persontiler.value}
          >
            {[tilersMarkup]}
          </IonSelect>
        </div>

        <div className="form-group has-select">
          <IonLabel position="stacked">
            Arbetsledare
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </IonLabel>
          <IonSelect
            name="personsupervisor"
            className="w-100"
            placeholder="Välj ett alternativ"
            onIonChange={this.onInputChange}
            value={formData.personsupervisor.value}
          >
            {(supervisors || []).map((supervisor: any) => (
              <IonSelectOption
                key={`supervisor-${parseInt(supervisor._id, 10)}`}
                value={parseInt(supervisor.personId, 10)}
              >
                {supervisor.name}
              </IonSelectOption>
            ))}
            ;
          </IonSelect>
        </div>

        <div className="form-group">
          <IonLabel position="stacked" className="m-0">
            Ort
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </IonLabel>
          <IonInput
            name="city"
            onIonChange={this.onInputChange}
            placeholder="Ex. Lorem ipsum dolor"
            type="text"
            autocapitalize="on"
            value={formData.city.value}
          />
        </div>

        <div className="form-group has-select">
          <IonLabel position="stacked">
            Dokumentet avser antal våtrum
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </IonLabel>
          <IonSelect
            name="wetroomamount"
            className="w-100"
            placeholder="Välj antal"
            onIonChange={this.onInputChange}
            value={formData.wetroomamount.value}
          >
            {Object.keys(numbers).map((num: string, index: number) => (
              <IonSelectOption key={index + 1} value={`${index + 1}`}>
                {index + 1}
              </IonSelectOption>
            ))}
          </IonSelect>
        </div>
      </IonItemGroup>
    );
  }
}

export default QualityDocumentSectionFour;
