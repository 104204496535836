import React from 'react';
import { IonIcon, IonLabel } from '@ionic/react';
import { person } from 'ionicons/icons';

export default (props: any) => {
  return (
    <div className="d-flex ion-align-items-center menu-user-info">
      <IonIcon icon={person} size="large" />
      <IonLabel>
        <h3 className="mb-0">{props.userName}</h3>
        <span className="text-muted font-size-xs">{props.companyName}</span>
      </IonLabel>
    </div>
  );
};
