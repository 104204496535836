import LimeAPI from '../classes/LimeAPI';
import store from '../store';
import { getType } from 'typesafe-actions';
import { updateProjects } from '../store/projects/actions';
import { updateMyProjects } from '../store/myProjects/actions';
import { currentUserIsAdmin } from './roles';

const abortController = new AbortController();

export const setProjectStatus = async (projectstatus: string, status_tiler: string) => {
  const { id, company } = store.getState().currentProject;

  const payload = {
    projectstatus,
    status_tiler,
    company,
  };

  try {
    const response = await LimeAPI.put(`projects/${id}`, payload, abortController.signal);
    const storePayload = {
      _id: id,
      projectstatus: response.data.projectstatus,
      status_tiler: response.data.status_tiler,
    };
    if (currentUserIsAdmin()) {
      store.dispatch({
        type: getType(updateProjects),
        payload: storePayload,
      });
    }
    store.dispatch({
      type: getType(updateMyProjects),
      payload: storePayload,
    });
  } catch (e) {
    return false;
  }
};

const getDocumentStatuses = () => {
  return store.getState().qualityDocuments.data.map((document: any) => document.qualitydocumentstatus.key);
};

const getLowestDocumentStatus = (statuses: string[]) => {
  const sortOrder = [
    'markasdone',
    'open',
    'approved',
  ];

  return statuses.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))[0];
};

export const getNewProjectStatus = () => {
  const lowestStatus = getLowestDocumentStatus(getDocumentStatuses());
  const statusMap: any = {
    open: {
      'status_tiler': 'actionrequired',
      'projectstatus': 'open',
    },
    markasdone: {
      'projectstatus': 'actionrequired',
      'status_tiler': 'open',
    },
    approved: {
      'projectstatus': 'approved',
      'status_tiler': 'approved',
    },
  };

  return statusMap[lowestStatus];
};

export const isProjectFinished = (project: any) => project.status.key === 'finished';
export const currentUserIsTiler = () => store.getState().currentQualityDocument.persontiler._id === store.getState().user.id;
export const userExistsInProject = () => Object.values(store.getState().currentProject.persons).flat().filter((person: any) => person.personId === store.getState().user.id).length > 0;
