import React from 'react';

class LoginSuccess extends React.Component<any> {
  constructor(props: any) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('relaystate');
    const data = {
      authStatus: 'success',
      token,
    };
    window.parent.postMessage(data, '*' );
  }

  render() {
    return '';
  }
}
export default LoginSuccess;
