import * as platformActions from './actions';
import { ActionType, getType } from 'typesafe-actions';

interface PlatformState {
  platform: string;
  uhi: string; /* Unit Hardware Id */
}

const defaultState: PlatformState = {
  platform: 'android',
  uhi: '',
};

export type PlatformAction = ActionType<typeof platformActions>;

export default (state = defaultState, action: PlatformAction): PlatformState => {
  switch (action.type) {
    case getType(platformActions.setPlatform):
      return { ...state, platform: action.payload };
    case getType(platformActions.setUhi):
      return { ...state, uhi: action.payload };
    default:
      return state;
  }
};