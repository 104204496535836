import { combineReducers } from 'redux';

import user from './user/reducer';
import projects from './projects/reducer';
import myProjects from './myProjects/reducer';
import currentProject from './currentProject/reducer';
import selectedTab from './selectedTab/reducer';
import qualityDocuments from './qualityDocuments/reducer';
import currentQualityDocument from './currentQualityDocument/reducer';
import appPages from './appPages/reducer';
import deviceInfo from './deviceInfo/reducer';

const appReducers = combineReducers({
  user,
  projects,
  myProjects,
  currentProject,
  selectedTab,
  qualityDocuments,
  currentQualityDocument,
  appPages,
  deviceInfo,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === 'app/RESET_APP') {
    window.location.replace('/login');
    state = undefined;
  }

  return appReducers(state, action);
};

export default rootReducer;
