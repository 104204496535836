import { loadingController, toastController } from "@ionic/core";
import { IonContent, IonPage, withIonLifeCycle } from "@ionic/react";
import React from "react";
import { connect } from "react-redux";
import LimeAPI from "../classes/LimeAPI";
import CreateProjectForm from "../components/CreateProject/CreateProjectForm";
import Header from "../components/Header/Header";
import { logEvent } from "../helpers/analytics";
import { newProjectState } from "../helpers/states";
import { validateForm } from "../helpers/validation";
import { actions, RootState } from "../store/index";

class PageNewProjects extends React.Component<any, any> {
  abortController = new AbortController();

  constructor(props: any) {
    super(props);
    this.state = newProjectState;

    this.createProject = this.createProject.bind(this);
    this.saveState = this.saveState.bind(this);
  }

  ionViewWillLeave() {
    this.abortController.abort();
  }

  capitalizeFirstLetter(string: String) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async createProject() {
    if (this.state.isSubmitting) {
      return;
    }

    this.setState({ isSubmitting: true });

    const { user, history } = this.props;
    const { name, supervisor, tiler } = this.state.formData;

    const formData = {
      name: name.value,
      company: user.company.id,
      supervisors: supervisor.value,
      tilers: tiler.value,
      isValid: false,
    };

    if (
      formData.name === "" ||
      formData.supervisors.length === 0 ||
      formData.tilers.length === 0
    ) {
        return;
    }

    const controller = await loadingController.create({
      spinner: "crescent",
      message: "Sparar projekt...",
    });

    await controller.present();

    const response = await LimeAPI.post(
      "projects/create",
      formData,
      this.abortController.signal
    );

    await controller.dismiss();

    if (!response.success) {
      const toast = await toastController.create({
        message: "Projektet kunde inte sparas, försök igen.",
        color: "dark",
        buttons: ["Ok"],
      });

      await toast.present();

      return;
    }

    this.props.addProject(response.data);

    if (
      formData.supervisors.includes(user.id) ||
      formData.tilers.includes(user.id)
    ) {
      this.props.addMyProject(response.data);
    }

    const createdToast = await toastController.create({
      message: `Projektet "${response.data.name}" har lagts till.`,
      duration: 3000,
      color: "success",
    });

    await createdToast.present();

    await logEvent("createProject", {
      id: response.data._id,
      timestamp: response.data._timestamp,
    });

    this.setState({ isSubmitting: false });

    return history.goBack();
  }

  saveState(event: any) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    if (name === "name") {
      value = this.capitalizeFirstLetter(value);
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: {
          value,
          isTouched: true,
          required: this.state.formData[name].required,
        },
      },
    });

    this.setState({
      isValid: validateForm(this.state),
    });
  }

  render() {
    return (
      <IonPage>
        <Header
          componentLeft="back"
          title="Nytt projekt"
          componentRight="createProject"
          componentRightHandler={this.createProject}
          componentSettings={this.state}
        />

        <IonContent>
          <CreateProjectForm
            projectState={this.state}
            saveState={this.saveState}
          />
        </IonContent>
      </IonPage>
    );
  }
}

const mapDispatchToProps = {
  addProject: (project: object) => actions.projects.addProject(project),
  addMyProject: (project: object) => actions.myProjects.addMyProject(project),
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withIonLifeCycle(PageNewProjects));
