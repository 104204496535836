import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonContent,
  IonText,
  IonPage
} from '@ionic/react';

import Header from '../components/Header/Header';

import '../components/Information/information-single.css';
import { connect } from 'react-redux';
import { RootState } from '../store';

class PageInformationDetail extends React.Component<any, any> {
  render() {
    if (this.props.appPages.pages === undefined) {
      return (<div />);
    }

    const item = this.props.appPages.pages.find((page: any) => page.slug === this.props.match.params.slug);

    return(
      <IonPage>
        <Header componentLeft="back" />
        <IonContent>
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol
                sizeSm="10"
                sizeMd="6"
                sizeLg="3"
                >
                <h1 className="information-title ion-text-center ion-margin-top ion-margin-bottom ion-text-xs-center ion-text-sm-left">
                  {item.title.rendered}
                </h1>

                <IonImg src={item.featured_image.full} className="ion-margin-bottom information-single-image" />

                <IonText color="dark" className="entry-content">
                  <div dangerouslySetInnerHTML={{ __html: item.content.rendered }} />
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { appPages: state.appPages };
};

export default withRouter(connect(mapStateToProps)(PageInformationDetail));
// const ConnectedPageInformationDetail = connect(mapStateToProps)(PageInformationDetail);
// const PageInformationDetailWithRouter = withRouter(ConnectedPageInformationDetail);

// export default PageInformationDetailWithRouter;
