import React from 'react';

export default (props: any) => {
  return (props.persons.length > 0 ?
  <div className="object-meta-group">
    <h2>{props.title}</h2>
    {props.persons.map((person: any) => {
      return (
        <div className="object-meta" key={person._id}>
          <span className="d-block">{person.name}</span>
          <span className="d-block font-size-xs">{person.title}</span>
        </div>
      );
    })}
  </div> : <div />);
};
