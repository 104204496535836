import React from "react";
import {
  IonIcon,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonImg,
  IonChip,
  IonLabel,
  IonAlert,
} from "@ionic/react";
import formatDate from "../../helpers/format-date";
import { trash, calendar, pricetag } from "ionicons/icons";
import formatSize from "../../helpers/format-size";
import { isImage } from "../../helpers/image";
import isBrowser from "../../helpers/is-browser";
declare var FileOpener: any;
declare var File: any;
import { isPlatform } from "@ionic/core";
import LimeAPI from "../../classes/LimeAPI";

class ProjectDocumentsModal extends React.Component<any, any> {
  abortController = new AbortController();

  constructor(props: any) {
    super(props);

    this.state = {
      isRemoveModalOpen: false,
    };

    this.downloadFile = this.downloadFile.bind(this);
  }

  createFileBlob() {
    const { document } = this.props;
    const { file } = document;

    const byteCharacters = atob(file.data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: file.content_type });
  }

  getFileUrl() {
    const blob = this.createFileBlob();

    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(blob);
  }

  async downloadFile(url: string, file: any) {
    let originalThis = this;
    document.addEventListener('deviceready', async function() {
      if (!isBrowser()) {
        const fileDir = isPlatform("ios")
          ? File.documentsDirectory
          : File.externalDataDirectory;

        try {
          const localFile = await File.writeFile(
            fileDir,
            file.name,
            originalThis.createFileBlob(),
            { replace: true }
          );
          return await FileOpener.open(localFile.nativeURL, file.content_type);
        } catch (err) {
          return false;
        }
      }
    });

    const elem = window.document.createElement("a");
    elem.href = url;
    elem.download = this.props.document.file.name;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }

  async removeDocument(id: number) {
    const response = await LimeAPI.delete(
      `documents/${this.props.document._id}`,
      this.abortController.signal
    );

    if (response.success) {
      this.props.documentIsRemoved(this.props.document._id);
      this.props.setShowModal(false);
    }
  }

  setShowRemoveModal(show: boolean) {
    this.setState({ isRemoveModalOpen: show });
  }

  render() {
    const url = this.getFileUrl();
    const { document } = this.props;
    const { file } = document;

    return (
      <>
        <IonModal
          isOpen={this.props.showModal}
          onDidDismiss={() => this.props.setShowModal(false)}
        >
          <IonHeader color="primary" className="app-header">
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonButton onClick={() => this.props.setShowModal(false)}>
                  Stäng
                </IonButton>
              </IonButtons>
              <IonTitle>Dokument</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => this.setShowRemoveModal(true)}>
                  <IonIcon icon={trash} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding ion-text-center">
            {isImage(file.extension) ? (
              <div>
                <IonImg src={url} className="ion-margin-bottom" />
              </div>
            ) : (
              <div />
            )}

            <div className="ion-margin-bottom">{document.title}</div>

            <div className="ion-margin-bottom">
              <IonButton onClick={() => this.downloadFile(url, file)}>
                Ladda ner filen
              </IonButton>
            </div>

            <IonChip color="secondary" style={{ pointerEvents: "none" }}>
              <IonIcon icon={calendar} />
              <IonLabel>{formatDate(document.date)}</IonLabel>
            </IonChip>

            <IonChip color="secondary" style={{ pointerEvents: "none" }}>
              <IonIcon icon={pricetag} />
              <IonLabel>{formatSize(document.file.size)}</IonLabel>
            </IonChip>
          </IonContent>
        </IonModal>

        <IonAlert
          isOpen={this.state.isRemoveModalOpen}
          onDidDismiss={() => this.setShowRemoveModal(false)}
          header={"Borttagning"}
          message={"Vill du ta bort dokumentet?"}
          buttons={[
            {
              text: "Nej",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Ja",
              handler: () => this.removeDocument(document._id),
            },
          ]}
        />
      </>
    );
  }
}

export default ProjectDocumentsModal;
