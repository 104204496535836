import React from 'react';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';
import { currentUserIsAdmin } from '../../helpers/roles';

class ProjectsFab extends React.Component {
  render() {
    if (! currentUserIsAdmin()) {
      return(<div />);
    }

    return(
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton dir="forward" routerLink="/projects/new/">
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
    );
  }
}

export default ProjectsFab;
