import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { actions } from '../store';

import Header from '../components/Header/Header';
import InformationList from '../components/Information/InformationList';
import { bkrUrl } from '../helpers/bkr';

import { IonContent, IonPage } from '@ionic/react';

class PageInformation extends React.Component<any> {
  async componentDidMount() {
    const pages = await this.getAppPages();
    this.setState({ pages }, () => this.setState({ isLoading: false }));
    this.props.setPages(pages);
  }

  async getAppPages() {
    const response = await fetch(`${bkrUrl}?no-cache=${Date.now()}`);
    return response.json();
  }

  render() {
    return(
      <IonPage>
        <Header
          componentLeft="logout"
          title="Dokument & information"
          componentRight="menu"
          />
        <IonContent>
          <InformationList />
        </IonContent>
      </IonPage>
    );
  }
}

const mapDispatchToProps = {
  setPages: (pages: any) => actions.appPages.setAppPages(pages),
};


export default withRouter(connect(undefined, mapDispatchToProps)(PageInformation));
// const ConnectedPageInformation = connect(undefined, mapDispatchToProps)(PageInformation);
// const PageInformationWithRouter = withRouter(ConnectedPageInformation);

// export default PageInformationWithRouter;

