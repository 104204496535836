import React from 'react';
import { IonButtons, IonButton } from '@ionic/react';
import { withRouter } from 'react-router-dom';

import logout from '../../helpers/logout';

export default withRouter((props: any) => {
  return (
    <IonButtons slot="start">
      <IonButton
        className="ion-color-text-color-secondary"
        onClick={() => logout() }
        routerDirection="back"
        >
        Logga ut
      </IonButton>
    </IonButtons>
  );
});
