import React from 'react';
import { IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';
import { isProjectFinished } from '../../helpers/project';
import store from '../../store';

export default (props: any) => {
  const { currentProject, qualityDocuments } = store.getState();
  if (isProjectFinished(currentProject)) {
    return(<div />);
  }

  // tslint:disable-next-line: strict-type-predicates
  if (qualityDocuments.data === undefined) {
    return (<div />);
  }

  const allowCloseProject = ((qualityDocuments.data).filter((document: any) =>
    document.qualitydocumentstatus.key === 'approved').length === qualityDocuments.data.length) &&
    qualityDocuments.data.length > 0;

  return (
  <IonGrid className="ion-padding-bottom ion-color-white">
    <IonRow className="ion-justify-content-center ion-text-center font-size-xs">
      <IonCol
      sizeSm="10"
      sizeMd="8"
      sizeLg="6"
      >
        <IonButton
        color={allowCloseProject ? 'danger' : 'light'}
        className="ion-margin-bottom"
        expand="block"
        disabled={! allowCloseProject}
        onClick={props.closeProject}
        >
        Avsluta projektet
        </IonButton>

        Projektet kan endast markeras som <strong>"Avslutat"</strong> när samtliga kvalitetsdokument är slutgodkända.
        </IonCol>
    </IonRow>
  </IonGrid>
  );
};
