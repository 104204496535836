import { ActionType, getType } from "typesafe-actions";
import { getRole, isAdmin } from "../../helpers/roles";
import * as user from "./actions";
import { User } from "./types";

const defaultState: User = {
  company: {
    id: 0,
    name: "",
    orgNo: "",
    qualificationNo: "",
    wallzone1supplier_text: "",
    wallzone2supplier_text: "",
    floorsupplier_text: "",
    responsibilityinsurance_text: "",
  },
  id: 0,
  identityNo: "",
  identityNoLong: "",
  isAdmin: false,
  name: "",
  permissionDate: "",
  role: "",
  sessionToken: "",
};

export type UserAction = ActionType<typeof user>;

export default (state = defaultState, action: UserAction): User => {
  switch (action.type) {
    case getType(user.setUser):
      return {
        id: action.payload._id,
        identityNo: action.payload.identityno,
        identityNoLong: action.payload.identityNoLong,
        name: action.payload.name,
        company: {
          id: action.payload.company,
          name: action.payload._embedded.relation_company.name,
          orgNo: action.payload._embedded.relation_company.registrationno,
          qualificationNo: action.payload._embedded.relation_company.qualificationno,
          wallzone1supplier_text: action.payload._embedded.relation_company.wallzone1supplier_text,
          wallzone2supplier_text: action.payload._embedded.relation_company.wallzone2supplier_text,
          floorsupplier_text: action.payload._embedded.relation_company.floorsupplier_text,
          responsibilityinsurance_text: action.payload._embedded.relation_company.responsibilityinsurance_text,
        },
        role: getRole(action.payload.position.key),
        isAdmin: isAdmin(action.payload.position.key),
        sessionToken: action.payload.sessionToken,
        permissionDate: action.payload.course1.substr(0, 10),
      };

      case getType(user.setCompanyDefaultStates):
        return {
          ...state,
          company: {
            ...state.company,
            wallzone1supplier_text: action.payload._embedded.relation_company.wallzone1supplier_text,
            wallzone2supplier_text: action.payload._embedded.relation_company.wallzone2supplier_text,
            floorsupplier_text: action.payload._embedded.relation_company.floorsupplier_text,
            responsibilityinsurance_text: action.payload._embedded.relation_company.responsibilityinsurance_text,
          },
        };
    default:
      return state;
  }
};
