import React from 'react';

import {
  IonGrid,
  IonRow,
  IonCol,
  IonList
} from '@ionic/react';

import InformationItem from './InformationItem';
import { connect } from 'react-redux';
import { RootState } from '../../store';

class InformationList extends React.Component<any> {
  render() {
    return (
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol
            sizeSm="10"
            sizeMd="8"
            sizeLg="6"
            >
            <IonList lines="none">
              {
                (this.props.appPages.pages || [])
                  .filter((page: any) => page.visibility.archive)
                  .map((item: any, index: number) => <InformationItem key={index} {...item} />)
              }
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { appPages: state.appPages };
};

export default connect(mapStateToProps)(InformationList);
