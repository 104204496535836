import React from 'react';

import LogOutButton from './LogOutButton';
import MenuButton from './MenuButton';
import BackButton from './BackButton';
import BackToProjectsButton from './BackToProjectsButton';
import BackToOverviewButton from './BackToOverviewButton';
import EditProject from './EditProject';
import CreateProjectButton from './CreateProjectButton';
import SaveProjectButton from './SaveProjectButton';
import CancelButton from './CancelButton';
import QualityDocumentActions from './QualityDocumentActions';
import CreateQualityDocumentButton from './CreateQualityDocumentButton';
import SaveQualityDocumentButton from './SaveQualityDocumentButton';

class HeaderComponent extends React.Component<any> {
    render() {
      const components: any = {
        logout: LogOutButton,
        menu: MenuButton,
        back: BackButton,
        cancel: CancelButton,
        editProject: EditProject,
        createProject: CreateProjectButton,
        saveProject: SaveProjectButton,
        qualityDocumentActions: QualityDocumentActions,
        createQualityDocumentButton: CreateQualityDocumentButton,
        saveQualityDocumentButton: SaveQualityDocumentButton,
        backToProjectsButton: BackToProjectsButton,
        backToOverviewButton: BackToOverviewButton,
      };

      const componentName = this.props.name !== undefined ? components[this.props.name] : '';
      const componentProps = this.props.handler !== undefined ? this.props.handler : '';
      const componentSettings = this.props.settings !== undefined ? this.props.settings : '';

      return componentName ? React.createElement(componentName, { handler: componentProps, settings: componentSettings }) : <></>;
    }
}

export default HeaderComponent;
