import React from 'react';
import store, { RootState } from '../../store/index';
import { add } from 'ionicons/icons';
import { IonCard, IonCardContent, IonText, IonIcon, withIonLifeCycle } from '@ionic/react';
import QualityDocumentsListItem from './QualityDocumentsListItem';
import { isProjectFinished, userExistsInProject } from '../../helpers/project';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class QualityDocumentsList extends React.Component<any, any> {
  render() {
    const { props } = this;
    const qualityDocuments = props.qualityDocuments.data;

    const project = store.getState().currentProject;
    const { id } = project;

    const createQualityDocument = ! isProjectFinished(project) && userExistsInProject() ?
      <IonCard
        routerLink={`/projects/${id}/qualitydocument/new`}
        className="card d-flex ion-align-items-center">
        <IonCardContent>
          <IonText color="primary" className="d-flex ion-align-items-center">
            <IonIcon icon={add} size="large" />
            Skapa nytt kvalitetsdokument
          </IonText>
        </IonCardContent>
      </IonCard> : <div />;

    const moreQualitydocuments = qualityDocuments.length >= 50 ?
    <IonText color="medium">Scrolla ner för att ladda in fler kvalitetsdokument</IonText> : <div />;

    return (
      <div className="card-group">
        {! props.isFetchingQualityDocuments && ((qualityDocuments && qualityDocuments.length > 0) || (userExistsInProject() && ! isProjectFinished(project))) ?
          <>
          <h2 className="card-group-title">{qualityDocuments && qualityDocuments.length > 0 && `${qualityDocuments.length} `}Kvalitetsdokument</h2>
          {moreQualitydocuments}
          </> : ''
        }

        {! props.isFetchingQualityDocuments && (qualityDocuments || []).map((document: any) => {
          const name = document.objectname;
          return <QualityDocumentsListItem
                  key={document._id}
                  project={{ id, name }}
                  document={{ ...document }} />;
        })}

        {createQualityDocument}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { qualityDocuments: state.qualityDocuments };
};

export default withRouter(connect(mapStateToProps)(withIonLifeCycle(QualityDocumentsList)));
// const QualityDocumentsListWithLifeCycle = withIonLifeCycle(QualityDocumentsList);
// const ConnectedQualityDocumentsList = connect(mapStateToProps)(QualityDocumentsListWithLifeCycle);
// const QualityDocumentsListWithRouter = withRouter(ConnectedQualityDocumentsList);

// export default QualityDocumentsListWithRouter;
