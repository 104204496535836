import React from 'react';
import store from '../store';
import { Redirect } from 'react-router-dom';
import UserSession from './UserSession';

export default function(ComposedComponent: any) {

  class RequireAuth extends React.Component <any, any> {

    constructor(props: any) {
      super(props);
      this.state = {
        notLoggedIn: false,
        logoutUser: false,
      };

      this.setUserData();
    }

    setUserData() {
      if (UserSession.exists()) {
        UserSession.setUserData(this.props)
          .then((data: any) => {
            if (! data.success) {
              store.dispatch({ type: 'app/RESET_APP' });
              this.setState({ logoutUser: true });
            }
          })
          .catch(() => false);
      }
    }

    render() {
      const condition = ! this.isUserLoggedIn() && ! this.state.notLoggedIn;
      if (condition) {
        if (! UserSession.exists()) {
          return <Redirect to="/login" />;
        } else if (UserSession.exists() && this.props.match.path !== '/projects') {
          return <Redirect to="/projects" />;
        }
      }

      if (this.state.logoutUser) {
        return <Redirect to="/login" />;
      }

      return <ComposedComponent { ...this.props } />;
    }

    isUserLoggedIn() {
      const userId = store.getState().user.id;

      if (userId > 0) {
        return true;
      }

      return false;
    }
  }

  return RequireAuth;
}
