import React from 'react';

import { IonItem, IonLabel, IonThumbnail, IonImg } from '@ionic/react';

import './information-item.css';

class InformationItem extends React.Component<any> {
  render() {
    return (
      <IonItem className="information-item" routerLink={`/information/${this.props.slug}`}>
        <IonThumbnail slot="start">
          <IonImg src={this.props.featured_image.thumbnail} />
        </IonThumbnail>

        <IonLabel>
          <h4 className="mb-0">{this.props.title.rendered}</h4>
        </IonLabel>
      </IonItem>
    );
  }
}

export default InformationItem;
