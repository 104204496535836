// tslint:disable-next-line: no-import-side-effect
import "@capacitor-community/firebase-analytics";
import { Plugins } from "@capacitor/core";
import isBrowser from "../helpers/is-browser";

const { FirebaseAnalytics } = Plugins;

// Read github page for more info
// https://github.com/capacitor-community/firebase-analytics

export default async () => {
  if (isBrowser()) {
    // tslint:disable-next-line
    await FirebaseAnalytics.initializeFirebase({
      apiKey: "AIzaSyBnZjJe0E1NYyRaEkPcHlSZ4rCq1Vs2JkQ",
      authDomain: "appbkrse.firebaseapp.com",
      databaseURL: "https://appbkrse.firebaseio.com",
      projectId: "appbkrse",
      storageBucket: "appbkrse.appspot.com",
      messagingSenderId: "994474954333",
      appId: "1:994474954333:web:31fdfd96bdb053cabe07b4",
      measurementId: "G-G4V3C49KTB",
    });
  }
};

export const logEvent = async (name: string, params: object = {}) => {
  // tslint:disable-next-line
  const result = await FirebaseAnalytics.logEvent({
    name,
    params,
  });

  return result;
};

export const setUserId = (userId: string) => {
  // tslint:disable-next-line
  FirebaseAnalytics.setUserId({
    userId,
  });
};

export const setScreenName = (
  screenName: string,
  nameOverride: any = undefined
) => {
  // tslint:disable-next-line
  FirebaseAnalytics.setScreenName({ screenName, nameOverride });
};
