import { IonCard, IonCardContent, IonIcon, IonText } from '@ionic/react';
import React from 'react';

import formatDate from '../../helpers/format-date';
import statusToColor from '../../helpers/status-to-color';
import { currentUserIsAdmin } from '../../helpers/roles';

class ProjectsListItem extends React.Component<any> {
  render() {
    const { project } = this.props;
    const { icon, color } = statusToColor(currentUserIsAdmin() ? project.projectstatus.key : project.status_tiler.key);
    const projectStatus = currentUserIsAdmin() ? project.projectstatus.text : project.status_tiler.text;

    const iconMarkup = icon !== '' ?
      <div className="ml-auto d-flex ion-align-items-center">
        <IonIcon className="card-icon" icon={icon} size="large" color={color}/>
      </div> : undefined;

    return(
      <IonCard
        className="ion-align-items-center d-flex card"
        routerLink={`/projects/${project._id}`}
        >
        <IonCardContent className="d-flex card-body">
          <div className="card-column">
            <h3 className="card-title text-truncate">{project.name}</h3>
            <div className="card-meta">
              <IonText color={color || 'medium'}>{projectStatus}</IonText>
              <IonText color="medium">{formatDate(project.created_datetime ? project.created_datetime : project._createdtime)}</IonText>
            </div>
          </div>
          {iconMarkup}
        </IonCardContent>
      </IonCard>
    );
  }
}

export default ProjectsListItem;
