import { StateType } from 'typesafe-actions';
import { Middleware } from 'redux';

import rootReducer from './root-reducer';

import * as userActions from './user/actions';
import * as projectsActions from './projects/actions';
import * as currentProjectActions from './currentProject/actions';
import * as myProjectsActions from './myProjects/actions';
import * as selectedTabActions from './selectedTab/actions';
import * as qualityDocumentsActions from './qualityDocuments/actions';
import * as currentQualityDocumentActions from './currentQualityDocument/actions';
import * as appPagesActions from './appPages/actions';
import * as deviceInfoActions from './deviceInfo/actions';

export { default } from './store';
export { default as rootReducer } from './root-reducer';

export const actions = {
  user: userActions,
  projects: projectsActions,
  myProjects: myProjectsActions,
  currentProject: currentProjectActions,
  selectedTab: selectedTabActions,
  qualityDocuments: qualityDocumentsActions,
  currentQualityDocument: currentQualityDocumentActions,
  appPages: appPagesActions,
  deviceInfo: deviceInfoActions,
};

export const middlewares: Middleware[] = [];

export type RootState = StateType<typeof rootReducer>;
