import React from 'react';

export default class ProjectUserInfo extends React.Component<any> {
  render() {
    return(
      <>
        <span className="font-size-sm">Hej! Du är inloggad som</span>
        <h1>{ this.props.name }</h1>
        <span className="font-size-sm">{this.props.company}</span>
      </>
    );
  }
}
