import React from 'react';
import { IonCard, IonCardContent, IonText, IonIcon } from '@ionic/react';
import formatDate from '../../helpers/format-date';
import formatSize from '../../helpers/format-size';
import { arrowForward } from 'ionicons/icons';

export default (props: any) => {
  const { document, setCurrentDocument } = props;

  return (
    <IonCard
      className="card ion-align-items-center d-flex w-100"
      onClick={() => setCurrentDocument(document)}>
      <IonCardContent className="card-body d-flex w-100">
        <div className="card-column">
          <h3 className="card-title text-truncate">{`${document.file.name}`}</h3>
          <div className="card-meta">
            <IonText color="medium">{formatSize(document.file.size)}</IonText>
            <IonText color="medium">{formatDate(document.date)}</IonText>
          </div>
        </div>
        <div className="ml-auto d-flex ion-align-items-center">
          <IonIcon className="card-icon" color="light" icon={arrowForward} size="small" />
        </div>
      </IonCardContent>
    </IonCard>
  );
};
