import React from 'react';

import {
  IonGrid,
  IonRow,
  IonCol,
  IonSlides
} from '@ionic/react';

import InformationSlideItem from './InformationSlideItem';
import { bkrUrl } from '../../helpers/bkr';

import './information-slider.css';
import { RootState, actions } from '../../store';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const slidesOptions = {
  slidesPerView: 'auto',
  grabCursor: true,
  preventClicks: true,
  spaceBetween: 30,
  autoHeight: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  breakpointsInverse: true,
  breakpoints: {
    1200: {
      slidesPerView: 3,
    },
  },
};

class InformationSlider extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: true,
      pages: false,
    };
  }

  async componentDidMount() {
    const pages = await this.getAppPages();
    this.setState({ pages }, () => this.setState({ isLoading: false }));
    this.props.setPages(pages);
  }

  async getAppPages() {
    const response = await fetch(`${bkrUrl}?no-cache=${Date.now()}`);
    return response.json();
  }

  render() {
    if (this.state.isLoading) {
      return (<div />);
    }
    return (
      <IonGrid className="ion-margin-top">
        <IonRow className="ion-justify-content-center">
          <IonCol
            sizeSm="10"
            sizeMd="8"
            >
              <h2>Information</h2>

              <IonSlides
                options={slidesOptions}
                pager={true}
                >
                {
                  this.state.pages
                    .filter((page: any) => page.visibility.front)
                    .map((item: any, index: number) => <InformationSlideItem key={index} {...item} />)
                }
              </IonSlides>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  }
}

function mapStateToProps(state: RootState): any {
  return {};
}

const mapDispatchToProps = {
  setPages: (pages: any) => actions.appPages.setAppPages(pages),
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(InformationSlider);
export default withRouter(ConnectedComponent);