import React from 'react';
import { connect } from 'react-redux';
import store, { RootState, actions } from '../store/index';
import { withRouter } from 'react-router-dom';
import { logEvent } from '../helpers/analytics';

import {
  IonContent,
  IonSearchbar,
  IonGrid,
  IonRow,
  IonCol,
  withIonLifeCycle,
  IonRefresher,
  IonRefresherContent,
  IonPage
} from '@ionic/react';

import ProjectsUserInfo from '../components/Projects/ProjectsUserInfo';
import ProjectsFab from '../components/Projects/ProjectsFab';
import ProjectsSegment from '../components/Projects/ProjectsSegment';
import Projects from '../components/Projects/Projects';
import Header from '../components/Header/Header';

import LimeAPI from '../classes/LimeAPI';
import ProjectsEmpty from '../components/Projects/ProjectsEmpty';
import ProjectListSkeleton from '../components/Projects/ProjectListSkeleton';
import { RefresherEventDetail } from '@ionic/core';
import UserSession from '../classes/UserSession';

class PageProjects extends React.Component<any, any> {
  projectsAbortController = new AbortController();
  myProjectsAbortController = new AbortController();
  projectsSearchAbortController = new AbortController();

  constructor(props: any) {
    super(props);

    this.state = {
      isFetching: true,
      component: <ProjectListSkeleton />,
      searchStr: '',
      projectsCount: {
        open: 0,
        closed: 0,
      },
      myProjectsCount: {
        open: 0,
        closed: 0,
      },
      filteredProjects: [],
      hasMoreProjects: false,
      hasMoreUserProjects: false,
      didEnter: false,
      refreshProjects: false,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.refreshProjects = this.refreshProjects.bind(this);
    this.handleSegmentCallback = this.handleSegmentCallback.bind(this);
  }

  handleSegmentCallback() {
    this.setState({ component: this.toggleProjectView() });

    if (this.state.searchStr === '') {
      return;
    }

    this.searchProjects().catch();
  }

  async handleSearch(event: any) {
    if (event.target.value === '') {
      this.setState({ searchStr: '', filteredProjects: [] }, () => {
        this.setState({ component: <Projects {...this.state}/> });
      });

      return;
    }

    this.setState({ searchStr: event.target.value });

    await this.searchProjects();
  }

  async ionViewWillEnter() {
    if (this.state.didEnter) { // hack for now, ionViewWillEnter ran twice... needs proper fix
      return;
    }

    this.setState({ didEnter: true });
    if (UserSession.exists() && this.props.user.identityNo === '') {
      setTimeout(async () => {
        await this.getProjects();
        this.setState({ isFetching: false }, () => this.setState({ component: this.toggleProjectView() }));
      }, 5000);
    } else {
      await this.getProjects();
      this.setState({ isFetching: false }, () => this.setState({ component: this.toggleProjectView() }));
    }
  }

  async getProjects(force = false) {
    if (! force && (this.props.projects.open.length || this.props.projects.closed.length)) {
      return;
    }

    if (!this.props.user.isAdmin) {
      return Promise.resolve(this.fetchUserProjects());
    }

    return Promise.all([this.fetchProjects(), this.fetchUserProjects()]);
  }

  async searchProjects() {
    const { isAdmin, id } = this.props.user;
    this.setState({ component: <ProjectListSkeleton /> });
    let params = {};

    logEvent('search_projects', {
      search_query: this.state.searchStr,
      scope: store.getState().selectedTab.value,
    });

    let endpoint = 'projects/userProjects';
    params = {
      pin: this.props.user.identityNo,
      searchString: this.state.searchStr,
      userId: id,
    };

    if (store.getState().selectedTab.value === 'allProjects' && isAdmin) {
      endpoint = 'projects/search';
      params = {
        companyId: this.props.user.company.id,
        searchString: this.state.searchStr,
      };
    }

    return LimeAPI.post(
      endpoint,
      params,
      this.projectsSearchAbortController.signal
    )
    .then(response => {
      if (! response.success) {
        this.setState({ filteredProjects: [], component: <ProjectsEmpty text={response.message} /> });
        return;
      }

      this.setState({ filteredProjects: response.data }, () => {
        this.setState({ component: <Projects {...this.state} /> });
      });
    })
    .catch(error => this.setState({ component: <ProjectsEmpty text="Inga projekt hittades" /> }));
  }

  async fetchProjects() {
    try {
      const response = await LimeAPI.post('projects/all', {
        companyId: this.props.user.company.id,
        pin: this.props.user.identityNo,
      }, this.projectsAbortController.signal);
      response.data.open_projects.sort((a: any, b: any) => (a._createdtime < b._createdtime) ? 1 : -1);
      response.data.closed_projects.sort((a: any, b: any) => (a._createdtime < b._createdtime) ? 1 : -1);
      response.data.open_projects_count = response.open_projects_count;
      response.data.closed_projects_count = response.closed_projects_count;

      response.data.refreshProjects = false;

      if (this.state.refreshProjects) {
        this.props.clearCurrentProject();
        response.data.refreshProjects = true;
      }

      this.setState({
        refreshProjects: false,
        hasMoreProjects: response.has_more_projects,
        projectsCount: {
          open: response.open_projects_count,
          closed: response.closed_projects_count,
        },
      });
      this.props.setProjects(response.data);
    } catch (error) {
      return false;
    }
  }

  async fetchUserProjects() {
    try {

      if (!this.props.user.identityNo || !this.props.user.id) {
        return false;
      }
      const response = await LimeAPI.post('projects/userProjects', {
        pin: this.props.user.identityNo,
        userId: this.props.user.id,
      }, this.myProjectsAbortController.signal);
      response.data.open_projects.sort((a: any, b: any) => (a.created_datetime < b.created_datetime) ? 1 : -1);
      response.data.closed_projects.sort((a: any, b: any) => (a.created_datetime < b.created_datetime) ? 1 : -1);
      response.data.open_projects_count = response.open_projects_count;
      response.data.closed_projects_count = response.closed_projects_count;
      response.data.refreshProjects = false;

      if (this.state.refreshProjects) {
        response.data.refreshProjects = true;
        this.props.clearCurrentProject();
      }

      this.setState({
        refreshProjects: false,
        hasMoreUserProjects: response.has_more_projects,
        myProjectsCount: {
          open: response.open_projects_count,
          closed: response.closed_projects_count,
        },
      });

      this.props.setMyProjects(response.data);
    } catch (error) {
      return false;
    }
  }

  async refreshProjects(event: CustomEvent<RefresherEventDetail>) {
    this.setState({ refreshProjects: true }, async () => {
      await this.getProjects(true);
      event.detail.complete();
    });
  }

  toggleProjectView() {
    if (this.state.isFetching) {
      return <ProjectListSkeleton />;
    }

    const projects = store.getState().selectedTab.value === 'myProjects' ? this.props.myProjects : this.props.projects;

    if ((projects.open && projects.open.length) || (projects.closed && projects.closed.length)) {
      return <Projects {...this.state}/>;
    }

    return <ProjectsEmpty text="Det finns inga projekt" />;
  }

  render() {
    const { user, projects } = this.props;
    const { isAdmin } = user;

    return (
      <IonPage>
        <Header
          componentLeft="logout"
          title="Projekt"
          componentRight="menu"
        />
        <IonContent className="has-fab-button">
          <IonRefresher slot="fixed" onIonRefresh={this.refreshProjects}>
            <IonRefresherContent
              refreshingSpinner="crescent">
            </IonRefresherContent>
          </IonRefresher>
          <IonGrid className="page-header ion-padding-top ion-padding-bottom">
            <IonRow className="ion-justify-content-center">
              <IonCol
                sizeSm="10"
                sizeMd="8"
                sizeLg="6"
                >
                <ProjectsUserInfo name={user.name} company={user.company.name} />
                { projects && <IonSearchbar debounce={400} onIonChange={this.handleSearch} color="primary" placeholder="Sök projektnamn" /> }
                { projects && isAdmin && <ProjectsSegment handleSegmentCallback={this.handleSegmentCallback} /> }
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid className="page-content ion-padding-top ion-padding-bottom">
            <IonRow className="ion-justify-content-center">
              <IonCol
                sizeSm="10"
                sizeMd="8"
                sizeLg="6"
                >
                {this.state.component}
              </IonCol>
            </IonRow>
          </IonGrid>

          <ProjectsFab />
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    projects: state.projects,
    myProjects: state.myProjects,
    selectedTab: state.selectedTab,
  };
};

const mapDispatchToProps = {
  setProjects: (projects: {
    open_projects: [];
    closed_projects: [];
    open_projects_count: number;
    closed_projects_count: number;
    refreshProjects: boolean;
  }) => actions.projects.setProjects(projects),
  setMyProjects: (projects: {
    open_projects: [];
    closed_projects: [];
    open_projects_count: number;
    closed_projects_count: number;
    refreshProjects: boolean;
  }) => actions.myProjects.setMyProjects(projects),
  clearCurrentProject: () => actions.currentProject.clearCurrentProject(),
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(PageProjects)));
// const PageWithLifeCycle = withIonLifeCycle(PageProjects);
// const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(PageWithLifeCycle);
// const PageWithRouter = withRouter(ConnectedPage);

// export default PageWithRouter;
