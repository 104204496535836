import store from "../store";
import { getType } from "typesafe-actions";
import * as qualityDocuments from "../store/qualityDocuments/actions";
import * as currentQualityDocumentActions from "../store/currentQualityDocument/actions";

export const storeQualityDocument = (data: any) => {
  store.dispatch({
    type: getType(qualityDocuments.updateQualityDocument),
    payload: {
      data,
    },
  });
};

const fieldsToVerify = ["copytoauthorizedcompany", "supplementtoclient"];

export const verifyQualityDocumentOnApproval = () => {
  const { currentQualityDocument }: any = store.getState();
  return fieldsToVerify.filter(
    (field: any) => currentQualityDocument[field] === false
  );
};

export const storeCurrentQualityDocument = (data: any) => {
  store.dispatch({
    type: getType(currentQualityDocumentActions.setQualityDocument),
    payload: {
      data,
    },
  });
};

export const qualityDocumentIsComplete = () => {
  const { currentQualityDocument }: any = store.getState();
  return currentQualityDocument.signdate !== null;
};
