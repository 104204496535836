import React from 'react';
import { withRouter } from 'react-router';

import {
  IonHeader,
  IonToolbar,
  IonTitle
} from '@ionic/react';

import HeaderComponent from './HeaderComponent';

import './header.css';

class Header extends React.Component<any, any> {
  render() {
    let color = this.props.color ? this.props.color : 'primary';
    if (color === 'success') {
      color = 'success-secondary';
    }

    return (
      <IonHeader color={color} className="app-header" mode="ios">
        <IonToolbar color={color}>
          <HeaderComponent name={this.props.componentLeft} handler={this.props.componentLeftHandler}/>

          <IonTitle>{this.props.title}</IonTitle>

          <HeaderComponent name={this.props.componentRight} handler={this.props.componentRightHandler} settings={this.props.componentSettings}/>
        </IonToolbar>
      </IonHeader>
    );
  }
}

export default withRouter<any, any>(Header);
