import store from "../store";

export default class LimeAPI {
  // tslint:disable-next-line: strict-boolean-conditions
  static apiUrl = process.env.REACT_APP_LIME_API_URL;

  static async request(
    endpoint: string,
    method: string,
    signal: AbortSignal,
    body = {}
  ) {
    const headers = {
      "Content-Type": "application/json",
      "Session-Token": store.getState().user.sessionToken
    };

    let args: object = { method, headers, signal };

    if (Object.keys(body).length > 0) {
      body = JSON.stringify(body);
      args = { body, ...args };
    }

    try {
      let url = `${this.apiUrl}/${endpoint}`;
      const request = await fetch(url, args);
      return request.json();
    } catch (error) {
      return error;
    }
  }

  static async get(endpoint: string, signal: AbortSignal) {
    return this.request(endpoint, "GET", signal);
  }

  static async post(endpoint: string, body: {}, signal: AbortSignal) {
    return this.request(endpoint, "POST", signal, body);
  }

  static async delete(endpoint: string, signal: AbortSignal) {
    return this.request(endpoint, "DELETE", signal);
  }

  static async put(endpoint: string, body: {}, signal: AbortSignal) {
    return this.request(endpoint, "PUT", signal, body);
  }
}
