import {
  IonContent,
  IonPage,
  IonSpinner,
  withIonLifeCycle,
} from "@ionic/react";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LimeAPI from "../classes/LimeAPI";
import Header from "../components/Header/Header";
import Project from "../components/Project/Project";
import { actions, RootState } from "../store/index";
import { QualityDocumentsInterface } from "../store/qualityDocuments/types";

class PageProject extends React.Component<any, any> {
  abortController = new AbortController();
  qualityDocumentAbortController = new AbortController();

  constructor(props: any) {
    super(props);
    this.state = {
      isFetching: true,
    };
  }

  async ionViewWillEnter() {
    this.setState({ isFetching: true });
    this.getQualityDocuments().catch(() => false);

    await this.getProject();
    this.setState({ isFetching: false });
  }

  async getProject() {
    if (parseInt(this.props.match.params.id, 10) === this.props.project.id) {
      return;
    }

    try {
      const project = await this.fetchProject();
      this.props.setCurrentProject(project);
    } catch {
      throw new Error();
    }
  }

  async fetchProject(): Promise<object> {
    return LimeAPI.get(
      `projects/${this.props.match.params.id}`,
      this.abortController.signal
    );
  }

  async getQualityDocuments() {
    const documents = await this.fetchQualityDocuments();
    this.props.setQualityDocuments(documents);

    this.setState({
      qualityDocuments: documents,
    });

    return true;
  }

  async fetchQualityDocuments(): Promise<object> {
    return LimeAPI.get(
      `projects/${this.props.match.params.id}/qualitydocuments`,
      this.qualityDocumentAbortController.signal
    );
  }

  render() {
    const { isFetching } = this.state;
    const { project } = this.props;
    const content = !isFetching ? (
      <Project />
    ) : (
      <IonSpinner name="crescent" color="primary" />
    );

    return (
      <IonPage>
        <Header
          componentLeft="backToOverviewButton"
          title={!isFetching ? `Projekt: ${project.name}` : ""}
          componentRight="editProject"
        />
        <IonContent className="page-wrapper">{content}</IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    project: state.currentProject,
  };
};

interface CurrentProjectInterface {
  _id: number;
  name: string;
  projectstatus: { key: string; text: string };
  status_tiler: { key: string; text: string };
  _createdtime: string;
  _timestamp: string;
  persons: object;
  company: number;
  misc: string;
}

const mapDispatchToProps = {
  setCurrentProject: (project: CurrentProjectInterface) =>
    actions.currentProject.setCurrentProject(project),
  setQualityDocuments: (documents: QualityDocumentsInterface) =>
    actions.qualityDocuments.setQualityDocuments(documents),
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withIonLifeCycle(PageProject))
);
// const PageWithLifeCycle = withIonLifeCycle(PageProject);
// const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(PageWithLifeCycle);
// const PageWithRouter = withRouter(ConnectedPage);

// export default PageWithRouter;
