import React from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon
} from '@ionic/react';

import LoginForm from './LoginForm';
import InformationSlider from '../Information/InformationSlider';

import './login.css';

class Login extends React.Component<any> {
  render() {
    return (
      <>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol
              sizeSm="10"
              sizeMd="8"
              sizeLg="5"
              sizeXl="3"
              className="ion-justify-content-center"
              >
              <IonIcon className="app-logo ion-text-center" src="assets/img/logo.svg" title="Byggkeramikrådet"></IonIcon>

              <LoginForm />
            </IonCol>
          </IonRow>
        </IonGrid>

        <InformationSlider />
      </>
    );
  }
}

export default Login;
