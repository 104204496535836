import React from 'react';
import { withRouter } from 'react-router-dom';

import { IonSlide } from '@ionic/react';

class InformationSlideItem extends React.Component<any> {
  render() {
    return (
      <IonSlide>
        <div
          onClick={() => this.props.history.push(`/information/${this.props.slug}`)}
          title={this.props.title}
          className="slide-inner ion-no-padding"
          >
          {this.props.featured_image && <img className="slide-img" src={this.props.featured_image.thumbnail} alt={this.props.title.rendered} />}

          <div className="slide-body">
            <h4 className="mb-0">{this.props.title.rendered}</h4>
          </div>
        </div>
      </IonSlide>
    );
  }
}

export default withRouter(InformationSlideItem);
