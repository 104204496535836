import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { RootState, actions } from '../../store/index';

import React from 'react';
import { connect } from 'react-redux';

class ProjectsSegment extends React.Component<any> {
  constructor(props: any) {
    super(props);

    this.handleSegmentChange = this.handleSegmentChange.bind(this);
  }

  handleSegmentChange(e: any) {
    this.props.setSelectedTab(e.target.value);
    this.props.handleSegmentCallback();
  }

  render() {
    return(
      <IonSegment>
        <IonSegmentButton onIonSelect={this.handleSegmentChange} value="myProjects" checked={this.props.selectedTab.value === 'myProjects'}>
          <IonLabel>Mina projekt</IonLabel>
        </IonSegmentButton>

        <IonSegmentButton onIonSelect={this.handleSegmentChange} value="allProjects" checked={this.props.selectedTab.value === 'allProjects'}>
          <IonLabel>Alla projekt</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedTab: state.selectedTab,
  };
};

const mapDispatchToProps = {
  setSelectedTab: (selectedTab: string) => actions.selectedTab.setSelectedTab(selectedTab),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsSegment);
