import store from '../store/store';
import LimeAPI from '../classes/LimeAPI';

export default () => {
  const abortsController = new AbortController();
  LimeAPI.delete(`authenticateUser/deleteUserSession/${store.getState().user.sessionToken}`, abortsController.signal)
    .then(() => localStorage.removeItem('orderRef'))
    .then(() => store.dispatch({ type: 'app/RESET_APP' }))
    .catch(() => false);
};
