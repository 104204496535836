import React from 'react';
import { IonItemGroup, IonItemDivider, IonItem, IonLabel, IonCheckbox } from '@ionic/react';
import { currentUserIsAdmin } from '../../helpers/roles';

class QualityDocumentSectionFive extends React.Component<any> {
  constructor(props: any) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event: any) {
    this.props.inputChangeCallback(event);
  }

  render() {
    const { formData } = this.props;
    let content;

    if (currentUserIsAdmin()) {
      content =
        <IonItemGroup className="form-section">
          <IonItemDivider sticky={true} mode="ios">Dokumentation</IonItemDivider>

          <div className="form-group form-group-simple form-group-cb">
            <IonItem lines="none" className="ion-no-padding">
              <IonCheckbox
                name="supplementtoclient"
                onIonChange={this.onInputChange}
                checked={formData.supplementtoclient.value}
              />
              <IonLabel>Kvalitetsdokument lämnad till beställare</IonLabel>
            </IonItem>

            <IonItem lines="none" className="ion-no-padding">
              <IonCheckbox
                name="copytoauthorizedcompany"
                onIonChange={this.onInputChange}
                checked={formData.copytoauthorizedcompany.value}
              />
              <IonLabel>Kopia sparad hos behörigt företag</IonLabel>
            </IonItem>
          </div>
        </IonItemGroup>;
    }

    return (
      <>
        { content }
      </>
    );
  }
}

export default QualityDocumentSectionFive;
