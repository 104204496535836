import { createAction } from 'typesafe-actions';

export const setCurrentProject = createAction('projects/SET_CURRENT_PROJECT', resolve =>
  (projects: {
    _id: number,
    name: string,
    projectstatus: { key: string, text: string },
    status_tiler: { key: string, text: string },
    _createdtime: string,
    _timestamp: string,
    company: number,
    persons: object,
    misc: string,
  }) => resolve(projects)
);

export const updateProject = createAction('projects/UPDATE_CURRENT_PROJECT', resolve =>
  (project: { _id: number, name: string, persons: object }) => resolve(project)
);

export const clearCurrentProject = createAction('projects/CLEAR_CURRENT_PROJECT', resolve =>
  () => resolve()
);
