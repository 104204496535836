import {
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React from "react";

class QualityDocumentSectionTwo extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event: any) {
    this.props.inputChangeCallback(event);
  }

  render() {
    const { formData } = this.props;

    return (
      <IonItemGroup className="form-section">
        <IonItemDivider sticky={true} mode="ios">
          Tätskiktssystem
        </IonItemDivider>

        <h2 className="ion-margin-bottom">
          Av Byggkeramikrådet godkända tätskiktssystem enligt BBV
        </h2>

        <div className="form-group-multiple has-select">
          <IonItem className="form-group-item" color="white" lines="none">
            <IonLabel position="stacked">
              Golv tillverkare/leverantör
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </IonLabel>
            <IonSelect
              name="companysupplierfloor"
              className="w-100"
              placeholder="Välj ett alternativ"
              onIonChange={this.onInputChange}
              value={formData.companysupplierfloor.value}
            >
              {(Object.keys(this.props.supplier) || []).map(
                (supplierKey: any) => {
                  const supplier = this.props.supplier[supplierKey];
                  return (
                    <IonSelectOption
                      key={`floor-${supplier._id}`}
                      value={supplier._id.toString()}
                    >
                      {supplier.suppliername}
                    </IonSelectOption>
                  );
                }
              )}
            </IonSelect>
          </IonItem>

          <IonItem className="form-group-item" color="white" lines="none">
            <IonLabel position="stacked">
              Golv systembenämning
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </IonLabel>
            <IonSelect
              name="suppliersystemfloor"
              className="w-100"
              placeholder="Välj ett alternativ"
              onIonChange={this.onInputChange}
              value={formData.suppliersystemfloor.value}
            >
              {(Object.keys(this.props.suppliersystemfloor) || []).map(
                (systemKey: any) => {
                  const system = this.props.suppliersystemfloor[systemKey];
                  return (
                    <IonSelectOption
                      key={`suppliersystemfloor-${system._id}`}
                      value={system._id.toString()}
                    >
                      {system.name}
                    </IonSelectOption>
                  );
                }
              )}
            </IonSelect>
          </IonItem>
        </div>

        <div className="form-group-multiple has-select">
          <IonItem className="form-group-item" color="white" lines="none">
            <IonLabel position="stacked">
              Vägg våtzon 1 tillverkare/leverantör
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </IonLabel>
            <IonSelect
              name="companywallzone1supplier"
              className="w-100"
              placeholder="Välj ett alternativ"
              onIonChange={this.onInputChange}
              value={formData.companywallzone1supplier.value}
            >
              {(Object.keys(this.props.supplier) || []).map(
                (supplierKey: any) => {
                  const supplier = this.props.supplier[supplierKey];
                  return (
                    <IonSelectOption
                      key={`wall-1-${supplier._id}`}
                      value={supplier._id.toString()}
                    >
                      {supplier.suppliername}
                    </IonSelectOption>
                  );
                }
              )}
            </IonSelect>
          </IonItem>

          <IonItem className="form-group-item" color="white" lines="none">
            <IonLabel position="stacked">
              Vägg våtzon 1 systembenämning
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </IonLabel>
            <IonSelect
              name="suppliersystemwallzone1"
              className="w-100"
              placeholder="Välj ett alternativ"
              onIonChange={this.onInputChange}
              value={formData.suppliersystemwallzone1.value}
            >
              {(Object.keys(this.props.suppliersystemwallzone1) || []).map(
                (systemKey: any) => {
                  const system = this.props.suppliersystemwallzone1[systemKey];
                  return (
                    <IonSelectOption
                      key={`suppliersystemwallzone1-${system._id}`}
                      value={system._id.toString()}
                    >
                      {system.name}
                    </IonSelectOption>
                  );
                }
              )}
            </IonSelect>
          </IonItem>
        </div>

        <div className="form-group-multiple has-select">
          <IonItem className="form-group-item" color="white" lines="none">
            <IonLabel position="stacked">
              Vägg våtzon 2 tillverkare/leverantör
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </IonLabel>
            <IonSelect
              name="companywallzone2supplier"
              className="w-100"
              placeholder="Välj ett alternativ"
              onIonChange={this.onInputChange}
              value={formData.companywallzone2supplier.value}
            >
              {(Object.keys(this.props.supplier) || []).map(
                (supplierKey: any) => {
                  const supplier = this.props.supplier[supplierKey];
                  return (
                    <IonSelectOption
                      key={`wall-2-${supplier._id}`}
                      value={supplier._id.toString()}
                    >
                      {supplier.suppliername}
                    </IonSelectOption>
                  );
                }
              )}
            </IonSelect>
          </IonItem>

          <IonItem className="form-group-item" color="white" lines="none">
            <IonLabel position="stacked">
              Vägg våtzon 2 systembenämning
              <span style={{ color: "red", fontSize: "20px" }}>*</span>
            </IonLabel>
            <IonSelect
              name="suppliersystemwallzone2"
              className="w-100"
              placeholder="Välj ett alternativ"
              onIonChange={this.onInputChange}
              value={formData.suppliersystemwallzone2.value}
            >
              {(Object.keys(this.props.suppliersystemwallzone2) || []).map(
                (systemKey: any) => {
                  const system = this.props.suppliersystemwallzone2[systemKey];
                  return (
                    <IonSelectOption
                      key={`suppliersystemwallzone2-${system._id}`}
                      value={system._id.toString()}
                    >
                      {system.name}
                    </IonSelectOption>
                  );
                }
              )}
            </IonSelect>
          </IonItem>
        </div>
      </IonItemGroup>
    );
  }
}

export default QualityDocumentSectionTwo;
