import React from 'react';
import { IonItemGroup, IonItem, IonLabel, IonRadioGroup, IonIcon } from '@ionic/react';
import { calendar, checkmarkCircle } from 'ionicons/icons';
import store from '../../../store';
import formatDate from '../../../helpers/format-date';

export default () => {
  const { currentQualityDocument } = store.getState();

  return (
    <IonItemGroup className="form-section">
      <IonItem className="form-group ion-no-padding" color="white" lines="none">
        <IonLabel position="stacked" className="m-0">Ansvarsförsäkring i försäkringsbolag</IonLabel>
        <div className="form-value">{currentQualityDocument.responsibilityinsurance}</div>
        <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
      </IonItem>

      <IonItem className="form-group ion-no-padding" color="white" lines="none">
        <IonLabel position="stacked" className="m-0">Objekt</IonLabel>
        <div className="form-value">{currentQualityDocument.objectname}</div>
        <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
      </IonItem>

      <IonItem className="form-group ion-no-padding" color="white" lines="none">
        <IonLabel position="stacked" className="m-0">Beställare</IonLabel>
        <div className="form-value">{currentQualityDocument.clientname}</div>
        <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
      </IonItem>

      <div className="form-group-multiple">
        <IonRadioGroup>
          <IonItem lines="none" color="white">
            <div className="form-value">{currentQualityDocument.newproduction ? 'Avser nyproduktion' : 'Avser renovering'}</div>
            <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
          </IonItem>
        </IonRadioGroup>
      </div>

      <h3>Arbetena utförda under perioden</h3>

      <IonItem className="form-group form-group-datetime ion-no-padding d-flex ion-align-items-center" color="white" lines="none">
        <IonIcon icon={calendar} color="primary" size="small" />
        {formatDate(currentQualityDocument.workfrom)}
        <span className="datetime-divider"></span>
        {formatDate(currentQualityDocument.workto)}

        <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
      </IonItem>
    </IonItemGroup>
  );
};
