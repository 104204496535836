export const showErrorMsg = (fieldName: string, state: any) => {
  const field = state.formData[fieldName];

  if (! field.required) {
    return false;
  }

  if (! field.isTouched) {
    return false;
  }

  const { value } = field;

  if (typeof value === 'string' && value === '') {
    return true;
  }

  if (typeof value === 'object' && value.length === 0) {
    return true;
  }

  return false;
};

export const validateForm = (state: any) => {
  let isValid = true;
  const { formData } = state;

  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {

      if (! formData[key].required) {
        continue;
      }

      if (! formData[key].hasOwnProperty('value')) {
        continue;
      }

      if (valueIsEmpty(formData[key])) {
        isValid = false;
        break;
      }
    } else {
      isValid = false;
    }
  }

  return isValid;
};

export const valueIsEmpty = (prop: any) => {
  return prop.value === null || prop.value === undefined || prop.value === '' || prop.value.length === 0;
};
