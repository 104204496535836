import React from 'react';
import ProjectsListItem from './ProjectsListItem';

class ProjectsList extends React.Component<any, any> {
  render() {
    if (! this.props.projects || this.props.projects.length === 0) {
      return false;
    }

    return(
      <div className="card-group">
        <h2 className="card-group-title">Visar {this.props.projects.length + ' av ' + this.props.count + ' ' + this.props.title}</h2>

        { this.props.projects.map(
          (project: any, i: number): any => {
            return (
              <ProjectsListItem project={{ ...project }} key={project._id}/>
            );
          }
        ) }
      </div>
    );
  }
}

export default ProjectsList;
