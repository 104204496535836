import * as myProjects from './actions';
import { MyProjects } from './types';
import { ActionType, getType } from 'typesafe-actions';

const defaultState: MyProjects = {
  open: [],
  closed: [],
  openProjectsCount: 0,
  closedProjectsCount: 0,
};

export type MyProjectsAction = ActionType<typeof myProjects>;

export default (state = defaultState, action: MyProjectsAction): MyProjects => {
  switch (action.type) {
    case getType(myProjects.setMyProjects):
      if (action.payload.refreshProjects) {
        return {
          open: action.payload.open_projects,
          closed: action.payload.closed_projects,
          openProjectsCount: action.payload.open_projects_count,
          closedProjectsCount: action.payload.closed_projects_count,
        };
      }

      return {
        ...state,
        open: action.payload.open_projects,
        closed: action.payload.closed_projects,
        openProjectsCount: action.payload.open_projects_count,
        closedProjectsCount: action.payload.closed_projects_count,
      };

    case getType(myProjects.addMyProject):
      const openProjects: any = [...state.open];
      openProjects.unshift(action.payload);

      return {
        ...state,
        open: openProjects,
        openProjectsCount: state.openProjectsCount + 1,
      };

      case getType(myProjects.addToMyProjects):
        const response: any = action;

        const open: any = [
          ...state.open,
          ...response.payload.data.open_projects,
        ];

        const closed: any = [
          ...state.closed,
          ...response.payload.data.closed_projects,
        ];

        return {
          ...state,
          open,
          closed,
        };

      case getType(myProjects.updateMyProjects):
        const allOpenProjects: any = [...state.open];
        const { payload }: any = action;

        const projectIndex = allOpenProjects.findIndex((project: any) => {
          return payload._id === project._id;
        });

        allOpenProjects[projectIndex] = {
          ...allOpenProjects[projectIndex],
          ...payload,
        };

        if (payload.projectstatus.key === 'finished') {
          const allClosedProjects: any = [...state.closed];
          allClosedProjects.push(allOpenProjects[projectIndex]);
          allOpenProjects.splice(projectIndex, 1);

          return {
            ...state,
            open: allOpenProjects,
            closed: allClosedProjects,
            openProjectsCount: state.openProjectsCount - 1,
            closedProjectsCount: state.closedProjectsCount + 1,
          };
        }

        return {
          ...state,
          open: allOpenProjects,
        };

      case getType(myProjects.deleteMyProjects):
        const projectId = action.payload._id;
        const myOpenProjects: any = [...state.open];
        const updatedMyProjects = myOpenProjects.filter((project: any) => project._id !== projectId);

        return {
          ...state,
          open: updatedMyProjects,
          openProjectsCount: state.openProjectsCount - 1,
        };

      default:
        return state;
  }
};
