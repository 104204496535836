import { IonApp, IonRouterOutlet, setupConfig } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import "./assets/css/global.css";
import RequireAuth from "./classes/RequireAuth";
import LoginError from "./components/Login/LoginError";
import LoginSuccess from "./components/Login/LoginSuccess";
import Menu from "./components/Menu/Menu";
import initAnalytics, { logEvent } from "./helpers/analytics";
import PageEditProject from "./pages/PageEditProject";
import PageEditQualityDocument from "./pages/PageEditQualityDocument";
import PageInformation from "./pages/PageInformation";
import PageInformationDetail from "./pages/PageInformationDetail";
import PageLogin from "./pages/PageLogin";
import PageNewProject from "./pages/PageNewProject";
import PageNewQualityDocument from "./pages/PageNewQualityDocument";
import PageProject from "./pages/PageProject";
import PageProjects from "./pages/PageProjects";
import PageQualityDocument from "./pages/PageQualityDocument";
import store from "./store";
import "./theme.css";
import { Device } from '@capacitor/device';
import { actions } from "./store/index";

setupConfig({
  swipeBackEnabled: false,
});

function Routes({ analytics }: { analytics: boolean }) {
  // const location = useLocation();

  // useEffect(() => {
  //   async function log() {
  //     await logEvent("pageView", location);
  //   }

  //   if (analytics) {
  //     // tslint:disable-next-line
  //     log();
  //   }
  // }, [location, analytics]);

  return (
    <IonRouterOutlet id="primary">
      <Route path="/login" component={PageLogin} exact />
      <Route path="/login-review-test-for-ios-and-android" component={PageLogin} exact />
      <Route path="/login-success" component={LoginSuccess} exact />
      <Route path="/login-error" component={LoginError} exact />
      <Route path="/projects" component={RequireAuth(PageProjects)} exact />
      <Route
        path="/projects/new"
        component={RequireAuth(PageNewProject)}
        exact
      />
      <Route path="/projects/:id" component={RequireAuth(PageProject)} exact />
      <Route
        path="/projects/:id/edit"
        component={RequireAuth(PageEditProject)}
        exact
      />
      <Route
        path="/projects/:id/qualitydocument/new"
        component={RequireAuth(PageNewQualityDocument)}
        exact
      />
      <Route
        path="/projects/:id/qualitydocument/:documentId/"
        component={RequireAuth(PageQualityDocument)}
        exact
      />
      <Route
        path="/projects/:id/qualitydocument/:documentId/edit"
        component={RequireAuth(PageEditQualityDocument)}
        exact
      />
      <Route
        path="/information"
        component={RequireAuth(PageInformation)}
        exact
      />
      <Route
        path="/information/:slug"
        component={PageInformationDetail}
        exact
      />
      <Route path="/" render={() => <Redirect to="/login" exact />} exact />
    </IonRouterOutlet>
  );
}

const App: React.FunctionComponent = () => {
  const [analytics, setAnalytics] = useState(false);

  useEffect(() => {
    async function init() {
      await initAnalytics();
      const info = await Device.getInfo();
      actions.deviceInfo.setPlatform(info.platform);
      setAnalytics(true);
    }

    // tslint:disable-next-line
    init();
  }, []);

  return (
    <IonApp>
      <Provider store={store}>
        <IonReactRouter>
          <Menu />
          <Routes analytics={analytics} />
        </IonReactRouter>
      </Provider>
    </IonApp>
  );
};

export default App;
