import React from 'react';
import { IonCard, IonCardContent, IonSkeletonText } from '@ionic/react';

export default () => {
  return (
    <IonCard className="d-flex ion-align-items-center card card-skeleton w-100">
      <IonCardContent className="d-flex w-100 card-body">
        <div className="card-column w-100">
          <h3 className="card-title"><IonSkeletonText animated style={{ width: '80%' }} /></h3>
          <div className="card-meta w-100">
            <IonSkeletonText animated style={{ width: '50%' }} />
          </div>
        </div>
        <div className="ml-auto">
          <IonSkeletonText className="card-skeleton-icon" animated />
        </div>
      </IonCardContent>
    </IonCard>
  );
};
