import React from 'react';
import { IonItemGroup, IonItem, IonLabel, IonItemDivider, IonCheckbox } from '@ionic/react';
import store from '../../../store';
import { currentUserIsAdmin } from '../../../helpers/roles';

export default () => {
  const { currentQualityDocument } = store.getState();
  let content;

  if (currentUserIsAdmin()) {
    content =
    <IonItemGroup className="form-section form-section-readonly">
      <IonItemDivider sticky={true} mode="ios">Dokumentation</IonItemDivider>

      <div className="form-group form-group-simple form-group-cb">
        <IonItem lines="none" className="ion-no-padding">
          <IonCheckbox name="supplementtoclient" disabled checked={currentQualityDocument.supplementtoclient} />
          <IonLabel>Kvalitetsdokument lämnad till beställare</IonLabel>
        </IonItem>

        <IonItem lines="none" className="ion-no-padding">
          <IonCheckbox name="copytoauthorizedcompany" disabled checked={currentQualityDocument.copytoauthorizedcompany} />
          <IonLabel>Kopia sparad hos behörigt företag</IonLabel>
        </IonItem>
      </div>
    </IonItemGroup>;
  }

  return (
    <>
      { content }
    </>
  );
};
