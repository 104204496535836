import { createAction } from 'typesafe-actions';

export const setMyProjects = createAction('projects/SET_MY_PROJECTS', resolve =>
  (projects: {
    open_projects: [],
    closed_projects: [],
    open_projects_count: number,
    closed_projects_count: number,
    refreshProjects: boolean,
  }) => resolve(projects)
);

export const addMyProject = createAction('projects/ADD_MY_PROJECT', resolve =>
  (project: object) => resolve(project)
);

export const addToMyProjects = createAction('projects/ADD_TO_MY_PROJECTS', resolve =>
  (projects: { open_projects: [], closed_projects: []}) => resolve(projects)
);

export const updateMyProjects = createAction('projects/UPDATE_MY_PROJECTS', resolve =>
  (project: { _id: number, name: string }) => resolve(project)
);

export const deleteMyProjects = createAction('projects/DELETE_MY_PROJECTS', resolve =>
  (project: { _id: number}) => resolve(project)
);
