import React from 'react';
import { IonSkeletonText } from '@ionic/react';
import ProjectSkeletonCard from './ProjectSkeletonCard';

class ProjectListSkeleton extends React.Component<any> {
  markup(key: any) {
    return (
      <div className="card-group" key={key}>
        <h2 className="card-skeleton-title"><IonSkeletonText animated style={{ width: '40%' }} /></h2>
        <ProjectSkeletonCard />
      </div>
    );
  }

  render() {
    return [1, 2].map(key => this.markup(key));
  }
}

export default ProjectListSkeleton;
