import React from 'react';
import { IonIcon } from '@ionic/react';
import { informationCircle } from 'ionicons/icons';

export default (props: any) => {
  return (
    <div className="ion-text-center">
      <IonIcon icon={informationCircle} size="large" color="primary" />
      <span className="h2 d-block">{props.text}</span>
    </div>
  );
};
