import React from 'react';
import { IonItemGroup, IonItem, IonLabel, IonIcon, IonItemDivider } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import store from '../../../store';

export default () => {
  const { currentQualityDocument } = store.getState();

  return (
    <IonItemGroup className="form-section">
      <IonItemDivider sticky={true} mode="ios">Behörig personal</IonItemDivider>

      <IonItem className="form-group ion-no-padding" color="white" lines="none">
        <IonLabel position="stacked">Plattsättare som applicerat tätskiktet</IonLabel>
        <div className="form-value">{currentQualityDocument.persontiler !== null ? currentQualityDocument.persontiler.name : currentQualityDocument.tilername}</div>
        <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
      </IonItem>

      <IonItem className="form-group ion-no-padding" color="white" lines="none">
        <IonLabel position="stacked">Arbetsledare</IonLabel>
        <div className="form-value">{currentQualityDocument.personsupervisor !== null ? currentQualityDocument.personsupervisor.name : currentQualityDocument.wetworkteamlead}</div>
        <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
      </IonItem>

      <IonItem className="form-group ion-no-padding" color="white" lines="none">
        <IonLabel position="stacked" className="m-0">Ort</IonLabel>
        <div className="form-value">{currentQualityDocument.city}</div>
        <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
      </IonItem>

      <IonItem className="form-group ion-no-padding" color="white" lines="none">
        <IonLabel position="stacked">Dokumentet avser antal våtrum</IonLabel>
        <div className="form-value">{currentQualityDocument.wetroomamount}</div>
        <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
      </IonItem>
    </IonItemGroup>
  );
};
