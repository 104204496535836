import React from 'react';
import { IonSelectOption, IonSelect, IonInput, IonLabel, IonGrid, IonRow, IonCol } from '@ionic/react';

import LimeAPI from '../../classes/LimeAPI';

import { actions } from '../../store/index';
import { connect } from 'react-redux';

import { showErrorMsg, validateForm } from '../../helpers/validation';
import { editProjectState } from '../../helpers/states';

import { loadingController, toastController } from '@ionic/core';

class EditProjectForm extends React.Component<any, any> {
  abortController = new AbortController();

  constructor(props: any) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
    this.saveState = this.saveState.bind(this);

    this.state = editProjectState(this.props.project.name);

    this.getCompanyPersons()
      .then(() => {
        const newState = this.state;
        newState.formData.supervisor.value = this.getObjectIds(this.props.project.persons.supervisors);
        newState.formData.tiler.value = this.getObjectIds(this.props.project.persons.tilers);
        newState.formData.prevParticipants = this.getPrevParticipants();

        this.setState({
          ...newState,
        });
      })
      .catch();
  }

  getPrevParticipants() {
    const tilers = this.props.project.persons.tilers.map((person: any) => {
      return {
        id: person._id.toString(),
        personId: person.personId.toString(),
        position: 'tiler',
      };
    });

    const supervisors = this.props.project.persons.supervisors.map((person: any) => {
      return {
        id: person._id.toString(),
        personId: person.personId.toString(),
        position: 'supervisor',
      };
    });

    return [...tilers, ...supervisors];
  }

  async componentDidUpdate() {
    if (this.props.isSubmitting) {
      this.updateProject();
      this.props.disableSubmit();
    }

    if (this.props.isDeleting) {
      await this.deleteProject();
      this.props.disableSubmit();
    }
  }

  async deleteProject() {
    const { project, setDeleteState } = this.props;

    setDeleteState(false);

    loadingController.create({
      spinner: 'crescent',
      message: 'Tar bort projekt...',
    }).then(controller => controller.present()).catch();

    const response = await LimeAPI.delete(`projects/${project.id}/`, this.abortController.signal);

    loadingController.dismiss().catch();

    if (! response.success) {
      toastController.create({
        message: 'Projektet kunde inte tas bort, försök igen.',
        buttons: ['Ok'],
        color: 'danger',
      }).then(toast => toast.present()).catch();

      return;
    }

    this.props.deleteMyProjects({ _id: project.id });
    this.props.deleteProjects({ _id: project.id });

    toastController.create({
      message: `Projektet har tagits bort.`,
      color: 'success',
      duration: 3000,
    }).then(toast => toast.present()).catch();

    return this.props.history.push('/projects');
  }

  updateProject() {
    const { name, supervisor, tiler, prevParticipants } = this.state.formData;
    const { user, project, history } = this.props;

    const formData = {
      id: project.id,
      name: name.value,
      supervisors: supervisor.value,
      tilers: tiler.value,
      company: user.company.id,
      prevParticipants,
    };

    loadingController.create({
      spinner: 'crescent',
      message: 'Sparar projekt...',
    }).then(controller => controller.present()).catch();

    LimeAPI.put(`projects/${formData.id}`, formData, this.abortController.signal)
      .then(response => {
        loadingController.dismiss().catch();

        if (! response.success) {
          toastController.create({
            message: 'Projektet kunde inte sparas, försök igen.',
            buttons: ['Ok'],
            color: 'danger',
          }).then(toast => toast.present()).catch();

          return;
        }

        this.props.updateCurrentProject(response.data);
        this.props.updateAllProjects(response.data);

        if (
            formData.supervisors.includes(user.id.toString()) ||
            formData.tilers.includes(user.id.toString())
          ) {
            this.props.updateMyProjects(response.data);
          }

        toastController.create({
          message: `Projektet "${response.data.name}" har uppdaterats.`,
          color: 'success',
          duration: 3000,
        }).then(toast => toast.present()).catch();

        return history.goBack();
      })
      .catch(() => false);
  }

  showErrorMsg(name: string) {
    return showErrorMsg(name, this.state);
  }

  selectedSupervisors() {
    const supervisors = this.props.project.persons.supervisors;

    if (this.state.supervisor.value.length !== 0) {
      return this.state.supervisor.value;
    }

    return this.getObjectIds(supervisors);
  }

  selectedTilers() {
    const tilers = this.props.project.persons.tilers;

    if (this.state.tiler.value.length !== 0) {
      return this.state.tiler.value;
    }

    return this.getObjectIds(tilers);
  }

  getObjectIds(object: any) {
    return (object || []).map((person: any) => {
      return person.personId.toString();
    });
  }

  onInputChange(event: any) {
    this.saveState(event);
  }

  saveState(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: {
          value,
          required: this.state.formData[name].required,
          isTouched: true,
        },
      },
    });

    this.props.onChangeHandler(validateForm(this.state));
  }

  getCompanyPersons() {
    return Promise.resolve(this.fetchCompanyPersons());
  }

  async fetchCompanyPersons(): Promise<any> {
    const url = `company/${this.props.user.company.id}/person?groupBy=position`;

    return LimeAPI.get(url, this.abortController.signal)
      .then(response => {
        this.setState({
          ...this.state,
          formData: {
            ...this.state.formData,
            companyPersons: response.data,
          },
        });
      })
      .catch(() => false);
  }

  render() {
    const { formData } = this.state;
    return (
      <form>
        <IonGrid className="page-header ion-padding-top ion-padding-bottom">
          <IonRow className="ion-justify-content-center">
            <IonCol
              sizeSm="10"
              sizeMd="8"
              sizeLg="6"
              >
              <div className="form-group ion-padding mb-0">
                <IonLabel>Projektnamn</IonLabel>
                <IonInput
                  name="name"
                  onIonChange={this.onInputChange}
                  required={formData.name.required}
                  value={formData.name.value || ''}
                />
                <span>{this.showErrorMsg('name') ? 'Obligatoriskt fält' : ''}</span>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid className="page-content ion-padding-top ion-padding-bottom">
          <IonRow className="ion-justify-content-center">
            <IonCol
              sizeSm="10"
              sizeMd="8"
              sizeLg="6"
              >
              <div className="form-group">
                <IonLabel>Arbetsledare</IonLabel>
                <IonSelect
                  name="supervisor"
                  multiple={true}
                  cancelText="Avbryt"
                  okText="Lägg till"
                  placeholder="Lägg till arbetsledare"
                  onIonChange={this.onInputChange}
                  value={formData.supervisor.value || []}
                >
                  {(formData.companyPersons.supervisors || []).map((person: { _id: string, name: string}) => {
                    return <IonSelectOption key={person._id} value={person._id.toString()}>{`${person.name}`}</IonSelectOption>;
                  })}
                </IonSelect>
                <span>
                  {this.showErrorMsg('supervisor') ? 'Obligatoriskt fält' : ''}
                </span>
              </div>

              <div className="form-group">
                <IonLabel>Plattsättare</IonLabel>
                <IonSelect
                  name="tiler"
                  multiple={true}
                  cancelText="Avbryt"
                  okText="Lägg till"
                  placeholder="Lägg till plattsättare"
                  onIonChange={this.onInputChange}
                  value={formData.tiler.value || []}
                >
                  {(formData.companyPersons.tilers || []).map((person: { _id: string, name: string}) => {
                    return <IonSelectOption key={person._id} value={person._id.toString()}>{`${person.name}`}</IonSelectOption>;
                  })}
                </IonSelect>
              </div>
              </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    );
  }
}

const mapDispatchToProps = {
  updateAllProjects: (project: any) => actions.projects.updateProjects(project),
  updateMyProjects: (project: any) => actions.myProjects.updateMyProjects(project),
  updateCurrentProject: (project: any) => actions.currentProject.updateProject(project),
  deleteMyProjects: (project: any) => actions.myProjects.deleteMyProjects(project),
  deleteProjects: (project: any) => actions.projects.deleteProjects(project)
};

export default connect(undefined, mapDispatchToProps)(EditProjectForm);
