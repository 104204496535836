import React from 'react';
import { IonCard, IonCardContent, IonText, IonIcon, IonSpinner } from '@ionic/react';
import { add } from 'ionicons/icons';
import ProjectDocumentsListItem from './ProjectDocumentsListItem';
import ProjectDocumentsModal from './ProjectDocumentsModal';
import { isProjectFinished } from '../../helpers/project';
import store from '../../store';
import LimeAPI from '../../classes/LimeAPI';
import { withRouter } from 'react-router-dom';
import { pictureToBase64 } from '../../helpers/picture';
import { loadingController, toastController } from '@ionic/core';

class ProjectDocuments extends React.Component<any, any> {
  abortController = new AbortController();
  fileInputRef: any;

  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      documents: [],
      currentDocument: false,
      modalIsOpen: false,
      showActionSheet: false,
      inputValue: '',
      picture: '',
      projectId: 0,
    };

    this.setCurrentDocument = this.setCurrentDocument.bind(this);
    this.setShowModal = this.setShowModal.bind(this);
    this.documentIsRemoved = this.documentIsRemoved.bind(this);

    this.fileInputRef = React.createRef();
  }

  async componentDidMount() {
    if (this.props.match.params.id === this.state.projectId) {
      this.setState({ isLoading: false });
      return;
    }

    const response = await this.getDocuments();
    this.setState({ documents: response.documents, projectId: this.props.match.params.id, isLoading: false });
  }

  async getDocuments() {
    return LimeAPI.get(`projects/${this.props.match.params.id}/documents`, this.abortController.signal);
  }

  setCurrentDocument(documentFile: any) {
    this.setState({ modalIsOpen: true, currentDocument: documentFile });
  }

  async uploadFile(data: any, filename: string, projectData: any) {

    loadingController.create({
      spinner: 'crescent',
      message: 'Laddar upp dokument...',
    }).then(controller => controller.present()).catch();

    const fileData = {
      data, filename,
    };

    const response = await LimeAPI.post('documents', { fileData, projectData }, this.abortController.signal);

    await loadingController.dismiss();

    if (! response.success) {
      const errorToast = await toastController.create({
        message: 'Kunde inte ladda upp dokument, försök igen.',
        color: 'dark',
        buttons: ['Ok'],
      });

      await errorToast.present();

      return;
    }

    const toast = await toastController.create({
      message: `Dokumentet har lagts till.`,
      duration: 3000,
      color: 'success',
    });

    await toast.present();

    const { documents } = this.state;
    documents.push(response.document);
    this.setState({ documents });
  }

  getProjectData() {
    const state = store.getState();
    return {
      project: state.currentProject.id,
      company: state.user.company.id,
      person: state.user.id,
    };
  }

  documentIsRemoved(id: number) {
    const documents = this.state.documents.filter((document: any) => document._id !== id);
    this.setState({ documents });
  }

  async selectFiles(files: any) {
    if (files[0] === undefined) {
      return;
    }

    const file = files[0];
    const base64File = await pictureToBase64(file);
    await this.uploadFile(base64File, file.name, this.getProjectData());
  }

  selectFile() {
    this.fileInputRef.current.click();
  }

  setShowModal(show: any) {
    this.setState({ modalIsOpen: show });
  }

  render() {
    const {
      currentDocument,
      modalIsOpen,
      documents,
      isLoading,
    } = this.state;

    return (
      <div className="card-group">
        <h2 className="card-group-title">Övriga Dokument</h2>

        {documents.length === 0 && isLoading ? <IonSpinner key="spinner" name="crescent" /> : ''}

        {documents.map((document: any) => {
          return <ProjectDocumentsListItem
            key={document._id}
            document={{ ...document }}
            setCurrentDocument={this.setCurrentDocument} />;
        })}

        {(modalIsOpen && currentDocument) ?
          <ProjectDocumentsModal
            showModal={modalIsOpen}
            setShowModal={this.setShowModal}
            document={currentDocument}
            documentIsRemoved={this.documentIsRemoved}
          /> : <div />
        }

        {
          ! isProjectFinished(store.getState().currentProject) ?
          <>
            <IonCard
              onClick={() => this.selectFile()}
              className="card d-flex ion-align-items-center cursor-pointer">
              <IonCardContent>
                <IonText color="primary" className="d-flex ion-align-items-center">
                  <IonIcon icon={add} size="large" /> Ladda upp övrigt dokument
                </IonText>
              </IonCardContent>
            </IonCard>

            <input
              type="file"
              accept=".jpg, .jpeg, .png, .gif, .doc, .docx, .pdf, .xls, .xlsx"
              style={{ display: 'none' }}
              ref={this.fileInputRef}
              onChange={(e: any) => this.selectFiles(e.target.files)}
              />
          </> : <div />
        }
      </div>
    );
  }
}

export default withRouter(ProjectDocuments);
