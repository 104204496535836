import React from "react";
import { RootState, actions } from "../../store/index";
import { connect } from "react-redux";
import { withRouter, Redirect, RouteComponentProps } from "react-router-dom";
import { Preferences } from "@capacitor/preferences";
import { APIUserInterface } from "../../store/user/actions";
import { App, AppState } from '@capacitor/app'; 
import { PluginListenerHandle } from '@capacitor/core';

import {
  IonButton,
  IonSpinner,
  withIonLifeCycle,
  IonNote,
  IonText,
  IonGrid,
  IonRow,
} from "@ionic/react";


import LimeAPI from "../../classes/LimeAPI";
import { logEvent } from "../../helpers/analytics"
import { Device } from '@capacitor/device';


interface LoginFormInterface {
  user: object;
  pin: string;
  loginActive: boolean;
  inputValid: boolean;
  ssn: string;
  rememberMe: boolean;
  sameDevice: boolean;
  errorMsg: string;
  isLoading: boolean;
  loginProcessActive: boolean;
  loginProcessFailed: boolean;
  loginSuccess: boolean;
  bankIdMessage: string;
  orderRef: string;
  debugText: string;
  currentQRImage?: string;
  imageLoadError: boolean; 
}

interface OwnProps {
  history: any;
}

interface StateProps {
  user: object;
}

interface DispatchProps {
  setUser: (object: any) => void;
}

type LoginFormProps = StateProps & DispatchProps & OwnProps & RouteComponentProps;

class LoginForm extends React.Component<LoginFormProps, LoginFormInterface> {
  appStateListener: PluginListenerHandle | null = null;
  intervalId: any;
  timeoutId: any;
  checkOrderRefStatus: any;
  abortController = new AbortController();
  _isMounted = false;
  constructor(props: LoginFormProps) {
    super(props);

    this.state = {
      loginActive: false,
      sameDevice: false,
      user: {},
      pin: "",
      inputValid: false,
      ssn: "",
      rememberMe: false,
      errorMsg: "",
      isLoading: false,
      loginProcessActive: false,
      loginProcessFailed: false,
      loginSuccess: false,
      bankIdMessage: "",
      orderRef: "",
      debugText: "",
      currentQRImage: undefined,
      imageLoadError: false,
    };

    this.handleInputCallback = this.handleInputCallback.bind(this);
    this.handleToggleCallback = this.handleToggleCallback.bind(this);
    this.login = this.login.bind(this);
    this.cancelLoginProcess = this.cancelLoginProcess.bind(this);
  }

  getQueryParams(param: string): string | null {
    const params = new URLSearchParams(this.props.location.search);
    return params.get(param);
  }

  async componentDidMount() {
    this._isMounted = true;
    this.getPinFromStorage().catch(() => false);
    this.appStateListener = App.addListener('appStateChange', this.handleAppStateChange);
    const orderRef = localStorage.getItem('orderRef');
    if (orderRef) {
      this.checkLoginStatus(orderRef);
    }
  
    const sessionId = this.getQueryParams('sessionId');
    if (sessionId) {
      this.checkLoginStatus(sessionId);
    }

    const info = await Device.getInfo();
    actions.deviceInfo.setPlatform(info.platform);

    if (this.props.location.pathname === "/login-review-test-for-ios-and-android") {
      if (process.env.REACT_APP_TEST_ACCOUNT) {
        this.setState({ pin: process.env.REACT_APP_TEST_ACCOUNT });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.abortController.abort();
    this.appStateListener?.remove();
    this.clearIntervals();
  }

  componentWillLeave() {
    this.setState({ errorMsg: "" });
  }

  handleAppStateChange = (state: AppState) => {
    if (state.isActive && this.state.orderRef) {
      this.checkLoginStatus(this.state.orderRef);
    }
  }

  async getPinFromStorage() {
    const userPin: any = await Preferences.get({ key: "userPin" });

    if (userPin.hasOwnProperty("value") && this._isMounted) {
      const hasValue = userPin.value !== null;
      this.setState({
        pin: hasValue ? userPin.value : "",
        inputValid: hasValue && userPin.value.length === 12,
        rememberMe: hasValue,
        loginActive: hasValue,
      });
    }
  }

  async checkLoginStatus(orderRef: any, qrCode = false) {
    const userStatus = await LimeAPI.post(
      "authenticateUser/checkUserStatus",
      { orderRef, sameDevice: this.state.sameDevice },
      this.abortController.signal
    );
    try {
      const { data } = userStatus;
      
      if (data.message) {
        this.setState({ bankIdMessage: data.message });
      }

      if (data.status === "Finished") {
        clearInterval(this.checkOrderRefStatus);
        if (data.signature, data.pin) {
          await this.loginUser(data.signature, data.pin);
          this.setState({ loginProcessFailed: false, isLoading: false });
        }
      } else {
        if (!qrCode) {
          this.setState({ isLoading: false, loginProcessActive: false });
        }
      }
    } catch {
      this.setState({
        errorMsg: "Något gick fel, försök igen.",
        isLoading: false,
        loginProcessFailed: true,
      });
    }
  }

  async authenticate(sameDevice: boolean, test = false) {
    this.setState({ bankIdMessage: "Legitimera dig i din BankID-app." });
    const info = await Device.getInfo();
    actions.deviceInfo.setPlatform(info.platform);

    LimeAPI.post(
      `authenticateUser`,
      { sameDevice, platform: info.platform},
      this.abortController.signal
    )
      .then((data) => {
        if (!data.success) {
          this.setState({ errorMsg: data.data.message, isLoading: false });
          return;
        }
        this.setState({ loginProcessActive: true, orderRef: data.data.orderRef, sameDevice: data.data.same_device });
        localStorage.setItem('orderRef', data.data.orderRef);
        localStorage.setItem('sameDevice', JSON.stringify(data.data.same_device));
        /* Same device redirect to bankID-app */
        if (data.data.redirect_url) {
          this.checkOrderRefStatus = setInterval(()=> { this.checkLoginStatus(data.data.orderRef)}, 1000);
          setTimeout(() => {
            clearInterval(this.checkOrderRefStatus);
            this.setState({ currentQRImage: undefined, loginProcessActive: false, isLoading: false, orderRef: "" })
          }, 30000);
          window.location.href = data.data.redirect_url;
        } else {
          if (data.data.qrCodeLink) {
            this.setState({currentQRImage: data.data.qrCodeLink});
            this.startIntervals();
          } else {
            this.setState({currentQRImage: ""});
          }
        }
      })
      .catch((error) => {
        this.setState({
          errorMsg: "Något gick fel, försök igen.",
          isLoading: false,
        });
      });
  }

  async login(event: any) {
    event.preventDefault();

    this.setState({ isLoading: true, errorMsg: "", loginProcessFailed: false });

    const pin = this.state.pin;
    const formattedPin = `${pin.slice(0, 8)}-${pin.slice(8)}`;
    if (
      process.env.REACT_APP_BANKID === "true" &&
      pin !== process.env.REACT_APP_TEST_ACCOUNT
    ) {
      this.authenticate(true);
    } else {
      const seconds = new Date().getTime() / 1000;
      const token = `some-random-string-021201lf-test${seconds}`;
      await this.loginUser(token, formattedPin); // only for test
    }
  }

  async bankID(sameDevice: boolean, test = false) {
    this.setState({ isLoading: true, errorMsg: "", loginProcessFailed: false, loginProcessActive: true });
    if (
      process.env.REACT_APP_BANKID === "true" && 
      this.props.location.pathname !== "/login-review-test-for-ios-and-android"
    ) {
      this.authenticate(sameDevice, test);
    } else {
      this.login({preventDefault: () => {}});
    }
  }

  async loginUser(authId: string, formattedPin: string) {

    logEvent('logged_in');
    try {
      const response = await LimeAPI.post(
        `personByPin`,
        { pin: formattedPin },
        this.abortController.signal
      );

      if (!response.success) {
        this.setState({ errorMsg: response.data.message, isLoading: false });
        return;
      }

      await this.setServerSession(authId, response, formattedPin);
    } catch {
      this.setState({
        errorMsg: "Något gick fel, försök igen.",
        isLoading: false,
      });
    }
  }

  async setServerSession(
    authId: string,
    personData: any,
    formattedPin: string
  ) {
    try {
      const yearDigits = `${formattedPin.slice(0, 2)}`;

      const response = await LimeAPI.post(
        `authenticateUser/storeUserSession`,
        {
          authId,
          userId: personData.person._id,
          companyId: personData.person.company,
          position: personData.person.position.key,
          year: yearDigits,
        },
        this.abortController.signal
      );
      if (!response.success) {
        this.setState({ errorMsg: response.data.message, isLoading: false });
        return;
      }

      window.sessionStorage.setItem("authId", authId);
      window.sessionStorage.setItem("userId", personData.person._id);

      personData.person.sessionToken = authId;
      personData.person.identityNoLong = formattedPin;

      this.setState({
        loginProcessActive: false,
        isLoading: false,
        loginSuccess: true,
      });
      this.props.setUser(personData.person);
    } catch (error) {
      this.setState({
        errorMsg: "Något gick fel, försök igen.",
        isLoading: false,
      });
    }
  }

  cancelLoginProcess() {
    this.setState({
      loginProcessActive: false,
      loginProcessFailed: false,
      bankIdMessage: "Legitimera dig i din BankID-app.",
    });
  }

  handleInputCallback(obj: { pin: string; inputValid: boolean }) {
    this.setState({ pin: obj.pin, inputValid: obj.inputValid });

    if (this.state.rememberMe) {
      Preferences.set({ key: "userPin", value: obj.pin }).catch()
    } else {
      Preferences.remove({ key: "userPin" }).catch();
    }
  }

  handleToggleCallback(checked: boolean) {
    const newState: any = {
      rememberMe: checked,
    };

    if (!checked) {
      newState.pin = "";
    }

    this.setState(newState);
  }

  isValidImage(qrImage: any) {
    if (!qrImage || qrImage === undefined) {
      return false;
    }
  
    try {
      const obj = JSON.parse(qrImage);
      return !(obj && obj.status);
    } catch (e) {
      return true;
    }
  }

  startIntervals = () => {
    this.clearIntervals();

    this.intervalId = setInterval(() => {
      this.checkLoginStatus(this.state.orderRef, true);
      if (this.isValidImage(this.state.currentQRImage) && typeof this.state.currentQRImage === 'string') {
        const url = new URL(this.state.currentQRImage);
        url.searchParams.set('t', new Date().getTime().toString());
        this.setState({ currentQRImage: url.toString() });
      } else {
        this.clearIntervals();
      }
    }, 1000);

    this.timeoutId = setTimeout(() => {
      this.setState({ currentQRImage: undefined, loginProcessActive: false, isLoading: false });
      clearInterval(this.intervalId);
    }, 25000);
  };

  clearIntervals = () => {
    clearInterval(this.intervalId);
    clearTimeout(this.timeoutId);
  };

  render() {
    let markup;

    if (!this.state.loginActive && !this.state.isLoading && !this.state.currentQRImage) {
      if (this.state.orderRef && this.state.bankIdMessage && this.state.bankIdMessage !== "Åtgärden avbruten." && this.state.bankIdMessage !== "Legitimera dig i din BankID-app." ) {
        markup = (
          <>
            <IonRow class="ion-justify-content-center ion-align-items-center ion-padding-top">
              <IonSpinner key="spinner" name="crescent" />
            </IonRow>
            <IonRow class="ion-justify-content-center ion-align-items-center ion-padding-top">
              <IonText className="ion-text-center">
                {this.state.bankIdMessage}
              </IonText>
            </IonRow>

            <IonRow class="ion-justify-content-center ion-align-items-center ion-padding-top">
              <IonButton
                onClick={() =>  {
                  this.setState({ currentQRImage: undefined, loginProcessActive: false, isLoading: false, orderRef: "" });
                  this.clearIntervals();
                }}
                key="cancelQrCode"
                color="dark"
                expand="block"
                >
                Avbryt
              </IonButton>
            </IonRow>
          </>
        );
      } else {
        markup = (
          <div key="bankIDbuttons">
            <IonButton
              onClick={() => this.bankID(true)}
              color="primary"
              expand="block"
              key="sameDeviceBankID"
              >
              Logga in med BankID
            </IonButton>
            <IonButton
              onClick={() => this.bankID(false)}
              color="dark"
              expand="block"
              class="ion-text-wrap"
              key="otherDeviceBankID"
            >
              Logga in med BankID på annan enhet
            </IonButton>
          </div>
        );
      }
    } else {
      if (this.state.currentQRImage && this.isValidImage(this.state.currentQRImage)) {
        markup = (
          <IonGrid>
            <IonRow class="ion-justify-content-center ion-align-items-center">
              <IonText className="login-message ion-text-center">
                {this.state.bankIdMessage}
              </IonText>
            </IonRow>
            <IonRow class="ion-justify-content-center ion-align-items-center ion-padding-top">
              <img src={this.state.currentQRImage} alt="QR Code"
                style={{ display: this.state.imageLoadError ? 'none' : 'block' }}
                onLoad={() => this.setState({ imageLoadError: false })}
                onError={() => {
                  this.setState({ imageLoadError: true });
              }} />
            </IonRow>
            <IonRow class="ion-justify-content-center ion-align-items-center ion-padding-top">
              <IonButton
                onClick={() =>  {
                  this.setState({ currentQRImage: undefined, loginProcessActive: false, isLoading: false, orderRef: "" });
                  this.clearIntervals();
                }}
                key="cancelQrCode"
                color="dark"
                expand="block"
              >
                Avbryt
              </IonButton>
            </IonRow>
          </IonGrid>
        );
      }
    
      if (!(this.state.currentQRImage && this.isValidImage(this.state.currentQRImage)) && this.state.isLoading) {
        markup = (
          <>
            <IonRow class="ion-justify-content-center ion-align-items-center ion-padding-top">
              <IonSpinner key="spinner" name="crescent" />
            </IonRow>
            <IonRow class="ion-justify-content-center ion-align-items-center ion-padding-top">
              <IonText className="ion-text-center">
                {this.state.bankIdMessage}
              </IonText>
            </IonRow>
          </>
        );
      }
    
      if (this.state.loginProcessFailed) {
        markup = (
          <IonNote
            className="login-failed-text ion-text-center"
            color="danger"
          >
            Inloggningen misslyckades. Försök igen.
          </IonNote>
        );
      }
    }

    if (this.state.loginSuccess) {
      return [markup, <Redirect key="projectRedirect" to="/projects" />];
    }

    if (!markup) {
      this.setState({
        loginActive: false,
        isLoading: false,
        currentQRImage: undefined,
        orderRef: "",
        bankIdMessage: "",
        loginProcessActive: false,
        loginProcessFailed: false,
        loginSuccess: false
      });
    }

    return [markup];
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    user: state.user,
  };
}

const mapDispatchToProps = {
  setUser: (user: APIUserInterface) => actions.user.setUser(user),
};

const LoginFormWithLifeCycle = withIonLifeCycle(LoginForm);
const ConnectedLoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginFormWithLifeCycle);
const LoginFormWithRouter = withRouter(ConnectedLoginForm);

export default LoginFormWithRouter;
