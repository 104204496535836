import * as selectedTab from './actions';
import { SelectedTab } from './types';
import { ActionType, getType } from 'typesafe-actions';

const defaultState: SelectedTab = {
  value: 'myProjects',
};

export type SelectedTabAction = ActionType<typeof selectedTab>;

export default (state = defaultState, action: SelectedTabAction): SelectedTab => {
  switch (action.type) {
    case getType(selectedTab.setSelectedTab):
      return {
        ...state,
        value: action.payload,
      };
    default:
      return state;
  }
};
