import React from 'react';
import { withRouter } from 'react-router-dom';
import { IonButtons, IonButton } from '@ionic/react';

export default withRouter((props: any) => {
  return (
    <IonButtons slot="start">
      <IonButton onClick={() => props.history.goBack()}>
        Avbryt
      </IonButton>
    </IonButtons>
  );
});
