import * as appPages from './actions';

import { ActionType, getType } from 'typesafe-actions';

import { AppPages } from './types';

const defaultState: AppPages = { pages: undefined };

export type AppPagesAction = ActionType<typeof appPages>;

export default (state = defaultState, action: AppPagesAction): AppPages => {
  switch (action.type) {
    case getType(appPages.setAppPages):
      const data: any = action;
      return {
        ...state,
        pages: [ ...data.payload ],
      };
    default:
      return state;
  }
};
