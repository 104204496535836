import { withRouter } from 'react-router-dom';
import { IonButtons, IonButton, IonIcon } from '@ionic/react';
import store from '../../store';

export default withRouter((props: any) => {
  function handleClick () {
    const id = store.getState().currentProject.id;
    props.history.push(`/projects/${id}`);
  }

  return (
    <IonButtons slot="start">
      <IonButton onClick={() => handleClick()}>
        <IonIcon name="arrow-back" />
      </IonButton>
    </IonButtons>
  );
});
