import React from 'react';
import { withRouter } from 'react-router-dom';
import { IonButtons, IonButton, IonIcon } from '@ionic/react';

export default withRouter((props: any) => {
  return (
    <IonButtons slot="start">
      <IonButton onClick={() => props.handler !== '' ? props.handler() : props.history.goBack()}>
        <IonIcon name="arrow-back" />
      </IonButton>
    </IonButtons>
  );
});
