import React from 'react';

import { IonIcon, IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonSpinner, IonRow, IonText } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';

class SignModal extends React.Component<any, any> {
  state = {
    currentQRImage: this.props.qrImage,
    imageLoadError: false,
  };

  handleCloseModal = () => {
    this.props.closeModal();
  }
  
  render() {
    const { props } = this;
  
    let content = [];

    if (props.isLoading) {
      content.push(<IonSpinner key="spinner" name="crescent" />);
    } else {
      content = [];
    }

    content.push(<p key="message">{props.message}</p>);

    return (
      <IonModal
        isOpen={props.showModal}
        >
        <IonHeader color="primary" className="app-header">
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonButton onClick={this.handleCloseModal}><IonIcon icon={closeCircle} /></IonButton>
            </IonButtons>
            <IonTitle>Signera kvalitetsdokument</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding ion-text-center">
          <IonRow class="ion-justify-content-center ion-align-items-center">
            <IonText className="login-message ion-text-center">
              {content}
            </IonText>
          </IonRow>
          <IonRow class="ion-justify-content-center ion-align-items-center ion-padding-top">
            {props.qrImage && !props.qrImage.startsWith('&') && !props.isLoading && (
              <img 
                src={props.qrImage} alt="QR Code"
                style={{ display: 'block' }}
                onError={() => {console.log("QR-image", props.qrImage)}}
              />
            )}
          </IonRow>
        </IonContent>
      </IonModal>
    );
  }
};

export default SignModal;
