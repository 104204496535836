import store from '../store/index';

const roles: any = {
  'combo': 'combo',
  'supervisor': 'admin',
  'tiler' : 'user',
};

export const getRole = (role: string) => roles[role] ? roles[role] : false;
export const isAdmin = (role: string) => ['supervisor', 'combo'].includes(role);
export const isAllowedRole = (role: string) => roles.hasOwnProperty(role);
export const currentUserIsAdmin = () => store.getState().user.isAdmin;
