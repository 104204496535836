import React from 'react';
import store from '../../store';
import { withRouter } from 'react-router';
import { IonButtons, IonButton, IonIcon } from '@ionic/react';
import { create } from 'ionicons/icons';
import { isProjectFinished } from '../../helpers/project';
import { currentUserIsAdmin } from '../../helpers/roles';

export default withRouter((props: any) => {
  const project = store.getState().currentProject;
  const projectId = project.id;

  if (isProjectFinished(project) || ! currentUserIsAdmin()) {
    return (<div />);
  }

  return (
    <IonButtons slot="end">
      <IonButton onClick={() => props.history.push(`/projects/${projectId}/edit`)}>
        <IonIcon icon={create} />
      </IonButton>
    </IonButtons>
  );
});
