import React from 'react';
import { IonIcon, IonText } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import store from '../../store';

export default (props: any) => {
  if (props.hideStatus) {
    return (<div />);
  }

  const { currentQualityDocument } = store.getState();
  const status = currentQualityDocument.qualitydocumentstatus;

  if (status === 'open') {
    return (<div />);
  }

  const color = status === 'markasdone' ? 'primary' : 'success';
  const label = status === 'markasdone' ?
    <>Detta kvalitetsdokument är markerad som <IonText color={color}>klart</IonText> av plattsättare.</> :
    <>Detta kvalitetsdokument är markerad som <IonText color={color}>slutgodkänt</IonText> av arbetsledare.</>;

  return (
  <div className="qty-document-status ion-margin-bottom ion-padding ion-text-center">
    <IonIcon
      icon={checkmarkCircle}
      size="large"
      color={color}
      className="d-block mx-auto"
      />
      {label}
  </div>
  );
};
