import { ActionType, getType } from 'typesafe-actions';
import * as qualityDocument from './actions';
import { QualityDocumentInterface } from './types';

const defaultState: QualityDocumentInterface = {
  _id: 0,
  authorizationno: '',
  city: '',
  clientname: '',
  company: '',
  companyname: '',
  copytoauthorizedcompany: false,
  floorfoundationok: false,
  floorslope: false,
  companysupplierfloor: '',
  suppliersystemfloor: '',
  newproduction: false,
  objectname: '',
  otherinformation: '',
  otherleadthrough: false,
  persontiler: {
    _id: 0,
    name: '',
  },
  personsupervisor: {
    _id: 0,
    name: '',
  },
  qualitydocumentstatus: '',
  picture: [],
  registrationno: '',
  renovation: false,
  responsibilityinsurance: '',
  sealingworkok: false,
  supplementtoclient: false,
  wallfoundationok: false,
  companywallzone1supplier: '',
  suppliersystemwallzone1: '',
  companywallzone2supplier: '',
  suppliersystemwallzone2: '',
  wellbyothercontractor: false,
  wellfixedmount: false,
  welltypeapproved: false,
  wetroomamount: '',
  tilername: '',
  wetworkteamlead: '',
  floorsupplier: '',
  floorsystem: '',
  wallzone1supplier: '',
  wallzone1system: '',
  wallzone2supplier: '',
  wallzone2system: '',
  companywallzone1supplier_text: '',
  companywallzone2supplier_text: '',
  floorsupplier_text: '',
  workfrom: '',
  workto: '',
  signdate: '',
  _createdtime: '',
  version: 0,
};

export type QualityDocumentActions = ActionType<typeof qualityDocument>;

export default (state = defaultState, action: QualityDocumentActions): QualityDocumentInterface => {
  switch (action.type) {
    case getType(qualityDocument.setQualityDocument):
      const { payload }: any = action;
      const newStatus = payload.data.qualitydocument.qualitydocumentstatus;


      if (newStatus === 'approved') {
        const data = {
          ...payload.data.qualitydocument,
        };

        if (typeof payload.data.related_documents !== 'undefined') {
          data.picture = [ ...payload.data.related_documents.objects.map((picture: any) => {
            return { data: picture.base64_string, extension: picture.file_extension };
          })];
        }

        return data;
      }

      return {
        ...payload.data.qualitydocument,
        picture: [ ...payload.data.related_documents.objects.map((picture: any) => {
          return { data: picture.base64_string, extension: picture.file_extension };
        })],
      };

    case getType(qualityDocument.clearQualityDocument):
      return defaultState;

    default:
      return state;
  }
};
