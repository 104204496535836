import { loadingController, toastController } from "@ionic/core";
import {
  IonCol,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  withIonLifeCycle,
} from "@ionic/react";
import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LimeAPI from "../../classes/LimeAPI";
import formatDate from "../../helpers/format-date";
import { setProjectStatus } from "../../helpers/project";
import store, { actions, RootState } from "../../store/index";
import { QualityDocumentsInterface } from "../../store/qualityDocuments/types";
import QualityDocumentsList from "../QualityDocuments/QualityDocumentsList";
import ProjectActions from "./ProjectActions";
import ProjectDocuments from "./ProjectDocuments";
import ProjectNotesForm from "./ProjectNotesForm";
import ProjectPersons from "./ProjectPersons";

class Project extends React.Component<any, any> {
  abortController = new AbortController();
  qualityDocumentAbortController = new AbortController();

  constructor(props: any) {
    super(props);

    this.state = {
      hasMoreQualitydocuments: false,
      qualitydocumentsOffset: 1,
    };

    this.closeProject = this.closeProject.bind(this);
  }

  async getNextQualitydocuments(e: CustomEvent<void>) {
    let offset = this.state.qualitydocumentsOffset;
    const documents = await LimeAPI.get(
      `projects/${this.props.match.params.id}/qualitydocuments?offset=${offset}`,
      this.qualityDocumentAbortController.signal
    );
    offset++;

    this.setState({ qualitydocumentsOffset: offset });

    this.props.addQualityDocuments(documents);

    if (e.target && e.target instanceof HTMLIonInfiniteScrollElement) {
      e.target.complete();
    }
  }

  closeProject() {
    loadingController
      .create({
        spinner: "crescent",
        message: "Avslutar projekt...",
      })
      .then((controller) => controller.present())
      .catch();

    setProjectStatus("finished", "finished")
      .then(() => {
        loadingController.dismiss().catch();

        toastController
          .create({
            message: "Projektet avslutades.",
            buttons: ["Ok"],
            color: "success",
          })
          .then((toast) => toast.present())
          .catch();

        return this.props.history.push("/projects");
      })
      .catch(() => false);
  }

  render() {
    const project = store.getState().currentProject;

    if (project.name === "") {
      return "";
    }

    const { isAdmin } = store.getState().user;
    const { tilers, supervisors } = project.persons;

    const infiniteScroll = this.props.qualitydocuments
      .hasMoreQualitydocuments ? (
      <IonInfiniteScroll
        threshold="600px"
        onIonInfinite={(e: CustomEvent<void>) => {
          if (e.target && e.target instanceof HTMLIonInfiniteScrollElement) {
            this.getNextQualitydocuments(e);
          }
      }}
      >
        <IonInfiniteScrollContent></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    ) : (
      <div />
    );

    return (
      <>
        <IonGrid className="page-header ion-padding-top ion-padding-bottom">
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="10" sizeMd="8" sizeLg="6">
              <h2>Projekt</h2>
              <h1>{project.name}</h1>

              <dl className="font-size-xs mt-0 ion-margin-bottom">
                <dt>{`Skapad: ${formatDate(project.createdTime)}`}</dt>
                <dt>{`Senast ändrad: ${formatDate(project.updatedTime)}`}</dt>
              </dl>

              <ProjectPersons
                title={`${supervisors.length} arbetsledare`}
                persons={supervisors}
              />
              <ProjectPersons
                title={`${tilers.length} plattsättare`}
                persons={tilers}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid className="page-content ion-padding-top ion-padding-bottom">
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="10" sizeMd="8" sizeLg="6">
              <QualityDocumentsList />

              {infiniteScroll}

              <ProjectDocuments />

              <ProjectNotesForm />
            </IonCol>
          </IonRow>
        </IonGrid>

        {isAdmin && <ProjectActions closeProject={this.closeProject} />}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    qualitydocuments: state.qualityDocuments,
  };
};

type ProjectProps = RouteComponentProps & {
  addQualityDocuments: typeof actions.qualityDocuments.addQualityDocuments;
};

const mapDispatchToProps = {
  addQualityDocuments: (documents: QualityDocumentsInterface) =>
    actions.qualityDocuments.addQualityDocuments(documents),
};

const ProjectWithLifeCycle = withIonLifeCycle(Project);
const ConnectedProject = connect(
  mapStateToProps, 
  mapDispatchToProps
)(ProjectWithLifeCycle);
const ProjectWithRouter = withRouter(ConnectedProject);

export default ProjectWithRouter;
