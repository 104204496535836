import { createAction } from 'typesafe-actions';
import { QualityDocumentInterface } from './types';

export const setQualityDocument = createAction('qualityDocument/SET_QUALITY_DOCUMENT', resolve =>
  (document: QualityDocumentInterface) => resolve(document)
);

export const clearQualityDocument = createAction('qualtiyDocument/CLEAR_QUALITY_DOCUMENT', resolve =>
  (document: {}) => resolve(document)
);
