import React, { useEffect, useState, useRef } from 'react';
import { IonContent, IonPage, IonButton } from '@ionic/react';
import { Device } from '@capacitor/device';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Login from '../components/Login/Login';
import ContactInfo from '../components/ContactInfo/ContactInfo';
import buildInfo from '../build-info.json';
import { CSSProperties } from 'react';

interface PageLoginProps extends RouteComponentProps {}

const PageLogin: React.FC<PageLoginProps> = ({ history }) => {
  const [buildDate, setBuildDate] = useState<string>("");
  const [platform, setPlatform] = useState<string>("");
  const [os, setOs] = useState<string>("");

  const [isHolding, setIsHolding] = useState<boolean>(false);
  const [holdStart, setHoldStart] = useState<number | null>(null);
  const [firstHoldCompleted, setFirstHoldCompleted] = useState<boolean>(false);
  const [clickCount, setClickCount] = useState<number>(0);
  const [secondHoldCompleted, setSecondHoldCompleted] = useState<boolean>(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  /**
   * Hidden Easter Egg Code
   * More information about how this code works and why it exists in the README.md file
   */
  useEffect(() => {
    setBuildDate(buildInfo.buildDate);

    const getPlatformInfo = async () => {
      const info = await Device.getInfo();
      setPlatform(info.platform);
      setOs(info.operatingSystem);
    };

    getPlatformInfo();
  }, []);

  useEffect(() => {
    if (isHolding && holdStart !== null) {
      timerRef.current = setTimeout(() => {
        if (Date.now() - holdStart >= 10000) {
          if (!firstHoldCompleted) {
            setFirstHoldCompleted(true);
            setClickCount(0);
          } else if (clickCount >= 10 && !secondHoldCompleted) {
            setSecondHoldCompleted(true);
            history.push('/login-review-test-for-ios-and-android');
          }
          setIsHolding(false);
          setHoldStart(null);
        }
      }, 10000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isHolding, holdStart, firstHoldCompleted, clickCount, secondHoldCompleted, history]);

  const handleTouchStart = () => {
    setIsHolding(true);
    setHoldStart(Date.now());
  };

  const handleTouchEnd = () => {
    setIsHolding(false);
    setHoldStart(null);
  };

  const handleClick = () => {
    if (firstHoldCompleted && !secondHoldCompleted) {
      setClickCount(prevCount => prevCount + 1);
    }
  };

  const renderBanner = () => {
    if (platform === 'web' && (os === 'ios' || os === 'android')) {
      if (os === 'ios') {
        return (
          <div style={bannerStyle}>
            <span>Använd gärna App-versionen istället.</span>
            <IonButton
              size="small"
              href="https://apps.apple.com/se/app/byggkeramikr%C3%A5det/id1503332901"
              color="primary"
              style={customButtonStyle}
            >
              Till App Store
            </IonButton>
          </div>
        );
      } else if (os === 'android') {
        return (
          <div style={bannerStyle}>
            <span>Använd gärna App-versionen istället.</span>
            <IonButton
              size="small"
              href="https://play.google.com/store/apps/details?id=se.bkr&hl=sv"
              color="primary"
              style={customButtonStyle}
            >
              Till Google Play
            </IonButton>
          </div>
        );
      }
    }
    return null;
  };

  const bannerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1rem',
    gap: '0.25rem',
    fontSize: '0.75rem',
  };

  const customButtonStyle: CSSProperties = {
    height: '2.5rem',
    fontWeight: 500,
  };

  const debugTextStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: '0.25rem',
    padding: '0.25rem',
    textAlign: 'right',
    fontSize: '8px',
    color: '#999999',
    zIndex: 9999,
  };

  return (
    <IonPage>
      <IonContent className="ion-no-padding">
        <div
          style={debugTextStyle}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onClick={handleClick}
        >
          {`${platform} - ${os} - ${buildDate}`}
        </div>
        {renderBanner()}
        <Login />
        <ContactInfo extraClasses="ion-text-center contact-info-small" />
      </IonContent>
    </IonPage>
  );
};

export default withRouter(PageLogin);
