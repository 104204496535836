import * as projects from './actions';
import { Projects } from './types';
import { ActionType, getType } from 'typesafe-actions';

const defaultState: Projects = {
  open: [],
  closed: [],
  openProjectsCount: 0,
  closedProjectsCount: 0,
};

export type ProjectsAction = ActionType<typeof projects>;

export default (state = defaultState, action: ProjectsAction): Projects => {
  switch (action.type) {
    case getType(projects.setProjects):
      if (action.payload.refreshProjects) {
        return {
          open: action.payload.open_projects,
          closed: action.payload.closed_projects,
          openProjectsCount: action.payload.open_projects_count,
          closedProjectsCount: action.payload.closed_projects_count,
        };
      }

      return {
        ...state,
        open: action.payload.open_projects,
        closed: action.payload.closed_projects,
        openProjectsCount: action.payload.open_projects_count,
        closedProjectsCount: action.payload.closed_projects_count,
      };

    case getType(projects.addProject):
      const openProjects: any = [...state.open];
      openProjects.unshift(action.payload);

      return {
        ...state,
        open: openProjects,
        openProjectsCount: state.openProjectsCount + 1,
      };

    case getType(projects.addProjects):
      const response: any = action;

      const open: any = [
        ...state.open,
        ...response.payload.data.open_projects,
      ];

      const closed: any = [
        ...state.closed,
        ...response.payload.data.closed_projects,
      ];

      return {
        ...state,
        open,
        closed,
      };

    case getType(projects.updateProjects):
      const allOpenProjects: any = [...state.open];
      const { payload }: any = action;

      const projectIndex = allOpenProjects.findIndex((project: any) => {
        return payload._id === project._id;
      });

      allOpenProjects[projectIndex] = {
        ...allOpenProjects[projectIndex],
        ...payload,
      };

      if (payload.projectstatus.key === 'finished') {
        const allClosedProjects: any = [...state.closed];
        allClosedProjects.push(allOpenProjects[projectIndex]);
        allOpenProjects.splice(projectIndex, 1);

        return {
          ...state,
          open: allOpenProjects,
          closed: allClosedProjects,
          openProjectsCount: state.openProjectsCount - 1,
          closedProjectsCount: state.closedProjectsCount + 1,
        };
      }

      return {
        ...state,
        open: allOpenProjects,
      };

    case getType(projects.deleteProjects):
      const projectId = action.payload._id;
      const projectsOpen: any = [...state.open];
      const updatedProjects = projectsOpen.filter((project: any) => project._id !== projectId);

      return {
        ...state,
        open: updatedProjects,
        openProjectsCount: state.openProjectsCount - 1,
      };

    default:
      return state;
  }
};
