import React from 'react';
import { IonButton, IonSpinner, IonIcon } from '@ionic/react';
import { isProjectFinished } from '../../helpers/project';
import store from '../../store';
import LimeAPI from '../../classes/LimeAPI';
import { actions } from '../../store/index';
import { connect } from 'react-redux';
import { checkmark } from 'ionicons/icons';

class ProjectNotesForm extends React.Component<any, any> {
  abortController = new AbortController();

  constructor(props: any) {
    super(props);
    this.state = {
      notes: store.getState().currentProject.misc,
      isChanged: false,
      isSubmitting: false,
      isSuccess: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onChange(event: any) {
    this.setState({
      notes: event.target.value,
      isSuccess: false,
      isChanged: true,
    });
  }

  async onClick() {
    this.setState({
      isSubmitting: true,
    });

    const { id, company } = store.getState().currentProject;

    const formData = {
      id,
      misc: this.state.notes,
      company,
    };

    const response = await LimeAPI.put(`projects/${formData.id}`, formData, this.abortController.signal);

    this.setState({ isSubmitting: false, isChanged: false, isSuccess: true });

    this.props.updateCurrentProject(response.data);
  }

  render() {
    const project = store.getState().currentProject;
    return (
      <div className="ion-margin-top-lg">
        <h2>Noteringar</h2>

        <div className="form-group">
          <textarea
            placeholder="Skriv dina noteringar här"
            readOnly={isProjectFinished(project)}
            value={this.state.notes}
            onChange={this.onChange}
          ></textarea>
        </div>

        {! isProjectFinished(project) ?
          <IonButton
          type="submit"
          color={this.state.isChanged ? 'primary' : this.state.isSuccess ? 'success' : 'medium'}
          disabled={! this.state.isChanged && ! this.state.isSubmitting}
          expand="block"
          onClick={this.onClick}>
            {
              this.state.isSubmitting ? <IonSpinner name="crescent" /> :
              this.state.isSuccess ?
                <>Noteringar sparade <IonIcon icon={checkmark} size="large" /></> :
                <>Spara noteringar</>
            }
          </IonButton> :
          <div />
        }
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateCurrentProject: (project: any) => actions.currentProject.updateProject(project),
};

export default connect(undefined, mapDispatchToProps)(ProjectNotesForm);
