import React from 'react';
import { IonItemGroup, IonItem, IonLabel, IonIcon, IonItemDivider } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import store from '../../../store';

export default () => {
  const { currentQualityDocument } = store.getState();

  return (
    <IonItemGroup className="form-section">
      <IonItemDivider sticky={true} mode="ios">Tätskiktssystem</IonItemDivider>

      <h2 className="ion-margin-bottom">Av Byggkeramikrådet godkända tätskiktssystem enligt BBV</h2>

      <div className="form-group-multiple has-select is-readonly">
        <IonItem className="form-group-item" lines="none">
          <IonLabel position="stacked">Golv tillverkare/leverantör</IonLabel>
          <div className="form-value">{currentQualityDocument.companysupplierfloor !== null ? currentQualityDocument.companysupplierfloor.name : currentQualityDocument.floorsupplier}</div>
          <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
        </IonItem>

        <IonItem className="form-group-item" lines="none">
          <IonLabel position="stacked">Golv systembenämning</IonLabel>
          <div className="form-value">{currentQualityDocument.suppliersystemfloor !== null ? currentQualityDocument.suppliersystemfloor.name : currentQualityDocument.floorsystem}</div>
          <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
        </IonItem>
      </div>

      <div className="form-group-multiple has-select is-readonly">
        <IonItem className="form-group-item" lines="none">
          <IonLabel position="stacked">Vägg våtzon 1 tillverkare/leverantör</IonLabel>
          <div className="form-value">{currentQualityDocument.companywallzone1supplier !== null ? currentQualityDocument.companywallzone1supplier.name : currentQualityDocument.wallzone1supplier}</div>
          <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
        </IonItem>

        <IonItem className="form-group-item is-readonly" lines="none">
          <IonLabel position="stacked">Vägg våtzon 1 systembenämning</IonLabel>
          <div className="form-value">{currentQualityDocument.suppliersystemwallzone1 !== null ? currentQualityDocument.suppliersystemwallzone1.name : currentQualityDocument.wallzone1system}</div>
          <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
        </IonItem>
      </div>

      <div className="form-group-multiple has-select is-readonly">
        <IonItem className="form-group-item" lines="none">
          <IonLabel position="stacked">Vägg våtzon 2 tillverkare/leverantör</IonLabel>
          <div className="form-value">{currentQualityDocument.companywallzone2supplier !== null ? currentQualityDocument.companywallzone2supplier.name : currentQualityDocument.wallzone2supplier}</div>
          <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
        </IonItem>

        <IonItem className="form-group-item is-readonly" lines="none">
          <IonLabel position="stacked">Vägg våtzon 2 systembenämning</IonLabel>
          <div className="form-value">{currentQualityDocument.suppliersystemwallzone2 !== null ? currentQualityDocument.suppliersystemwallzone2.name : currentQualityDocument.wallzone2system}</div>
          <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
        </IonItem>
      </div>
    </IonItemGroup>
  );
};
