import React from 'react';
import ProjectsList from './ProjectsList';

import { connect } from 'react-redux';
import store, { RootState, actions } from '../../store/index';
import { withRouter } from 'react-router-dom';
import { IonInfiniteScroll, IonInfiniteScrollContent, IonSpinner } from '@ionic/react';
import LimeAPI from '../../classes/LimeAPI';

class Projects extends React.Component<any, any> {
  abortController = new AbortController();

  constructor(props: any) {
    super(props);

    this.state = {
      allProjectsOffset: 1,
      myProjectsOffset: 1,
      hasMoreProjects: this.props.hasMoreProjects,
      hasMoreUserProjects: this.props.hasMoreUserProjects,
    };
  }

  async getNextProjects(e: CustomEvent<void>) {
    const selectedTab = store.getState().selectedTab.value;
    const offsetString = `${selectedTab}Offset`;
    const offsetState = this.state[selectedTab + 'Offset'];
    this.setState({ [offsetString]: offsetState + 1 }, async () => {
      let endpoint = '';
      let params = {};

      if (selectedTab === 'allProjects') {
        const offset = offsetState * 10;
        endpoint = 'projects/all';
        params = {
          companyId: store.getState().user.company.id,
          offset,
          pin: store.getState().user.identityNo,
        };
      } else {
        const openCount = this.props.myProjects.open.length;
        const closedCount = this.props.myProjects.closed.length;
        const offset = openCount + closedCount;
        endpoint = 'projects/userProjects';
        params = {
          pin: store.getState().user.identityNo,
          userId: store.getState().user.id,
          offset,
          openCount,
          closedCount,
        };
      }

      const newProjects = await LimeAPI.post(endpoint, params, this.abortController.signal);

      if (selectedTab === 'myProjects') {
        this.props.addToMyProjects(newProjects);
        this.setState({ hasMoreUserProjects: newProjects.has_more_projects });
      } else {
        this.props.addProjects(newProjects);
        this.setState({ hasMoreProjects: newProjects.has_more_projects });
      }
    });

    if(e.target !== null && 'complete' in e.target && typeof e.target.complete === 'function') {
      await e.target.complete();
    }
  }


  render() {
    const selectedTab = this.props.selectedTab.value;
    const property = selectedTab === 'myProjects' ? 'myProjects' : 'projects';
    const filteredProjects = this.props.filteredProjects;

    const openProjects = ! Array.isArray(filteredProjects) ? filteredProjects.open_projects : this.props[property].open;
    const closedProjects = ! Array.isArray(filteredProjects) ? filteredProjects.closed_projects : this.props[property].closed;

    const openProjectsTitle = openProjects !== undefined && openProjects.length === 1 ? 'öppet' : 'öppna';
    const closedProjectsTitle = closedProjects !== undefined && closedProjects.length === 1 ? 'avslutat' : 'avslutade';

    const enableInfiniteScroll = store.getState().selectedTab.value === 'myProjects' ? this.state.hasMoreUserProjects : this.state.hasMoreProjects;

    const infiniteScroll = enableInfiniteScroll ?
      <IonInfiniteScroll
        threshold="100px"
        onIonInfinite={(e: CustomEvent<void>) => this.getNextProjects(e)}>
        <IonInfiniteScrollContent>
          <div style={{ textAlign: 'center' }}>
            <IonSpinner name="crescent" key="spinner" />
          </div>
        </IonInfiniteScrollContent>
      </IonInfiniteScroll> : <div />;

    return (
      <>
        <ProjectsList
          title={openProjectsTitle + ' projekt'}
          count={this.props[property].openProjectsCount}
          projects={Object.values(openProjects || {})} />
        <ProjectsList
          title={closedProjectsTitle + ' projekt'}
          count={this.props[property].closedProjectsCount}
          projects={Object.values(closedProjects || {})} />

        {infiniteScroll}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    projects: state.projects,
    myProjects: state.myProjects,
    selectedTab: state.selectedTab,
  };
};

const mapDispatchToProps = {
  addProjects: (projects: any) => actions.projects.addProjects(projects),
  addToMyProjects: (projects: any) => actions.myProjects.addToMyProjects(projects),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Projects));
// const ProjectsWithRouter = withRouter(Projects);
// export default connect(mapStateToProps, mapDispatchToProps)(ProjectsWithRouter);
