import * as currentProject from './actions';

import { ActionType, getType } from 'typesafe-actions';

import { CurrentProject } from './types';

const defaultState: CurrentProject = {
  id: 0,
  name: '',
  createdTime: '',
  updatedTime: '',
  status: {
    key: '',
    label: '',
  },
  statusTiler: {
    key: '',
    label: '',
  },
  company: 0,
  persons: {},
  misc: '',
};

export type MyProjectsAction = ActionType<typeof currentProject>;

export default (state = defaultState, action: MyProjectsAction): CurrentProject => {
  switch (action.type) {
    case getType(currentProject.setCurrentProject):
      return {
        ...state,
        id: action.payload._id,
        name: action.payload.name,
        status: {
          key: action.payload.projectstatus.key,
          label: action.payload.projectstatus.text,
        },
        statusTiler: {
          key: action.payload.status_tiler.key,
          label: action.payload.status_tiler.text,
        },
        createdTime: action.payload._createdtime,
        updatedTime: action.payload._timestamp,
        persons: action.payload.persons,
        company: action.payload.company,
        misc: action.payload.misc,
      };

    case getType(currentProject.updateProject):
      return {
        ...state,
        ...action.payload,
      };

    case getType(currentProject.clearCurrentProject):
      return defaultState;

    default:
      return state;
  }
};
