import { createAction } from 'typesafe-actions';
import { QualityDocumentsInterface } from './types';

export const setQualityDocuments = createAction('qualityDocuments/SET_QUALITY_DOCUMENTS', resolve =>
  (documents: QualityDocumentsInterface) => resolve(documents)
);

export const addQualityDocument = createAction('qualityDocuments/ADD_QUALITY_DOCUMENT', resolve =>
  (document: QualityDocumentsInterface) => resolve(document)
);

export const addQualityDocuments = createAction('qualityDocuments/ADD_QUALITY_DOCUMENTS', resolve =>
  (document: QualityDocumentsInterface) => resolve(document)
);

export const updateQualityDocument = createAction('qualityDocuments/UPDATE_QUALITY_DOCUMENT', resolve =>
  (document: QualityDocumentsInterface) => resolve(document)
);

export const deleteQualityDocument = createAction('qualityDocuments/DELETE_QUALITY_DOCUMENT', resolve =>
  (document: {}) => resolve(document)
);
