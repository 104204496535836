import React from 'react';
import { IonItemGroup, IonItem, IonLabel, IonRadioGroup, IonIcon, IonItemDivider, IonCheckbox } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import store from '../../../store';

export default () => {
  const { currentQualityDocument } = store.getState();

  const pictures = currentQualityDocument.picture.length > 0 ?
    <>
      <p>Uppladdade bilder</p>
      <div className="form-group form-group-simple form-group-images d-flex">
        { currentQualityDocument.picture.map((picture: any, index: number) =>
          <div className="image-col" key={index}><IonItem lines="none" color="white"><img src={`data:image/${picture.extension};base64,${picture.data}`} alt="" /></IonItem></div>
        )}
      </div>
    </> : '';

  const notes = currentQualityDocument.otherinformation !== '' ?
  <>
    <p>Övriga upplysningar/eventuella avvikelser från Byggkeramikrådets Branschregler för Våtrum (BBV) </p>
    <div className="form-group">
      <IonItem lines="none" color="white" className="ion-no-padding">
        <div className="form-value">{currentQualityDocument.otherinformation}</div>
        <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
      </IonItem>
    </div>
  </> : '';

  const controlText = currentQualityDocument.version <= 3 ?
  'Egenkontrollen innefattar följande kontrollpunkter med hänvisning till kapitel 6:533 i Boverkets byggregler, BFS 2011:6 med ändringar t.o.m. BFS 2015:3, BBR'
  :
  'Egenkontrollen innefattar följande kontrollpunkter med hänvisning till kapitel 6:533 i Boverkets byggregler, BFS 2011:6 med ändringar t.o.m. BFS 2020:4, BBR';

  return (
    <IonItemGroup className="form-section form-section-readonly">
      <IonItemDivider sticky={true} mode="ios">Egenkontroll</IonItemDivider>

      <h2 className="ion-margin-bottom">{controlText}</h2>

      <p>Underlagen uppfyller branschreglernas krav (Golv)</p>
      <div className="form-group-multiple">
        <IonRadioGroup>
          <IonItem lines="none" color="white">
            <div className="form-value">{currentQualityDocument.floorfoundationok === false ? 'Nej' : 'Ja'}</div>
            <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
          </IonItem>
        </IonRadioGroup>
      </div>

      <p>Underlagen uppfyller branschreglernas krav (Vägg)</p>
      <div className="form-group-multiple">
        <IonRadioGroup>
          <IonItem lines="none" color="white">
            <div className="form-value">{currentQualityDocument.wallfoundationok === false ? 'Nej' : 'Ja'}</div>
            <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
          </IonItem>
        </IonRadioGroup>
      </div>

      <p>Finns andra rörgenomföringar genom golvets tätskikt än avlopp</p>
      <div className="form-group-multiple">
        <IonRadioGroup>
          <IonItem lines="none" color="white">
            <div className="form-value">{currentQualityDocument.otherleadthrough === false ? 'Nej' : 'Ja'}</div>
            <IonIcon icon={checkmarkCircle} className="form-group-icon" color="success" size="small" />
          </IonItem>
        </IonRadioGroup>
      </div>

      {notes}

      <div className="form-group form-group-simple form-group-cb">
        <IonItem lines="none" className="ion-no-padding">
          <IonCheckbox name="floorslope" disabled checked={currentQualityDocument.floorslope} />
          <IonLabel>Lutning på golv mot golvavlopp uppfyller branschreglernas krav (1:50–1:150/1:100–1:200) innan tätskikt applicerats.</IonLabel>
        </IonItem>

        <IonItem lines="none" className="ion-no-padding">
          <IonCheckbox name="welltypeapproved" disabled checked={currentQualityDocument.welltypeapproved} />
          <IonLabel>Har ny typgodkänd golvbrunn installerats eller är befintlig golvbrunn typgodkänd och intakt?</IonLabel>
        </IonItem>

        <IonItem lines="none" className="ion-no-padding">
          <IonCheckbox name="wellbyothercontractor" disabled checked={currentQualityDocument.wellbyothercontractor} />
          <IonLabel>Ny golvbrunn monterad av annan entreprenör</IonLabel>
        </IonItem>

        <IonItem lines="none" className="ion-no-padding">
          <IonCheckbox name="wellfixedmount" disabled checked={currentQualityDocument.wellfixedmount} />
          <IonLabel>Golvbrunnen är fast monterad, korrekt placerad och i nivå med tätskiktet</IonLabel>
        </IonItem>

        <IonItem lines="none" className="ion-no-padding">
          <IonCheckbox name="sealingworkok" disabled checked={currentQualityDocument.sealingworkok} />
          <IonLabel>Tätskiktsarbetet är utfört enligt aktuell monteringsanvisning</IonLabel>
        </IonItem>
      </div>

      {pictures}
    </IonItemGroup>
  );
};
