import { createAction } from 'typesafe-actions';

export const setProjects = createAction('projects/SET_PROJECTS', resolve =>
    (projects: {
      open_projects: [],
      closed_projects: [],
      open_projects_count: number,
      closed_projects_count: number,
      refreshProjects: boolean,
    }) => resolve(projects)
);

export const addProject = createAction('projects/ADD_PROJECT', resolve =>
  (project: object) => resolve(project)
);

export const addProjects = createAction('projects/ADD_PROJECTS', resolve =>
  (projects: any) => resolve(projects)
);

export const updateProjects = createAction('projects/UPDATE_PROJECTs', resolve =>
  (project: { _id: number, name: string }) => resolve(project)
);

export const deleteProjects = createAction('projects/DELETE_PROJECTS', resolve =>
  (project: { _id: number }) => resolve(project)
);
