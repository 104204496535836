import React from 'react';

export default (props: any) => {
  return(
    <div className={`contact-info${props.extraClasses ? ` ${props.extraClasses}` : ''}`}>
      <p>Byggkeramikrådet AB<br />
      Högbergsgatan 27<br />
      116 20 Stockholm</p>

      <p>Tel: <a href="tel:08-641 21 25">08-641 21 25</a><br />
      E-post: <a href="mailto:info@bkr.se">info@bkr.se</a><br />
      Webbplats: <a href="https://www.bkr.se">bkr.se</a>

      </p>
    </div>
  );
};
