import React from "react";
import {
  IonItemGroup,
  IonItem,
  IonLabel,
  IonInput,
  IonRadioGroup,
  IonRadio,
  IonDatetime,
  IonIcon,
} from "@ionic/react";
import { calendar } from "ionicons/icons";
import dayjs from "dayjs";
import store from "../../store";

class QualityDocumentSectionOne extends React.Component<any> {
  constructor(props: any) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event: any) {
    this.props.inputChangeCallback(event);
  }

  render() {
    const { formData } = this.props;

    const { qualificationNo } = store.getState().user.company;
    const isNewQualificationNoDateFormat = qualificationNo.indexOf("20") === 0;
    let year = qualificationNo.substr(0, 4);
    let minDate;

    if (parseInt(year) < 2021) {
      minDate = "2021-01-01";
    } else {
      minDate = isNewQualificationNoDateFormat
        ? `${year}-${qualificationNo.substr(4, 2)}-01`
        : "2021-01-01";
    }

    return (
      <IonItemGroup className="form-section">
        <IonItem
          className="form-group ion-no-padding"
          color="white"
          lines="none"
        >
          <IonLabel position="stacked" className="m-0">
            Ansvarsförsäkring i försäkringsbolag
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </IonLabel>
          <IonInput
            name="responsibilityinsurance"
            type="text"
            placeholder="Ex. Lorem ipsum dolor"
            onIonChange={this.onInputChange}
            autocapitalize="on"
            value={formData.responsibilityinsurance.value}
          />
        </IonItem>

        <IonItem
          className="form-group ion-no-padding"
          color="white"
          lines="none"
        >
          <IonLabel position="stacked" className="m-0">
            Objekt
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </IonLabel>
          <IonInput
            name="objectname"
            type="text"
            placeholder="Ex. Lorem ipsum dolor"
            onIonChange={this.onInputChange}
            value={formData.objectname.value}
            autocapitalize="on"
          />
        </IonItem>

        <IonItem
          className="form-group ion-no-padding"
          color="white"
          lines="none"
        >
          <IonLabel position="stacked" className="m-0">
            Beställare
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
          </IonLabel>
          <IonInput
            name="clientname"
            type="text"
            placeholder="Ex. Lorem ipsum dolor"
            onIonChange={this.onInputChange}
            autocapitalize="on"
            value={formData.clientname.value}
          />
        </IonItem>

        <div className="form-group-multiple">
          <IonRadioGroup>
            <IonItem lines="none" color="white">
              <IonRadio
                name="newproduction"
                onIonSelect={this.onInputChange}
                checked={formData.newproduction.value}
                value={true}
              />
              <IonLabel>Avser nyproduktion</IonLabel>
            </IonItem>
          </IonRadioGroup>
          <IonRadioGroup>
            <IonItem lines="none" color="white">
              <IonRadio
                name="renovation"
                onIonSelect={this.onInputChange}
                checked={formData.renovation.value}
                value={true}
              />
              <IonLabel>Avser renovering</IonLabel>
            </IonItem>
          </IonRadioGroup>
        </div>

        <h3>
          Arbetena utförda under perioden
          <span style={{ color: "red", fontSize: "24px" }}>*</span>
        </h3>

        <IonItem
          className="form-group form-group-datetime ion-no-padding d-flex ion-align-items-center"
          color="white"
          lines="none"
        >
          <IonIcon icon={calendar} color="primary" />
          <IonDatetime
            displayFormat="YYYY-MM-DD"
            placeholder="Från"
            onIonChange={this.onInputChange}
            name="workfrom"
            min={minDate}
            max={dayjs().format("YYYY-MM-DD")}
            value={formData.workfrom.value}
          />
          <span className="datetime-divider"></span>
          <IonDatetime
            displayFormat="YYYY-MM-DD"
            placeholder="Till"
            onIonChange={this.onInputChange}
            name="workto"
            min={minDate}
            max={dayjs().format("YYYY-MM-DD")}
            value={formData.workto.value}
          />
        </IonItem>
      </IonItemGroup>
    );
  }
}

export default QualityDocumentSectionOne;
