import * as qualityDocuments from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { QualityDocumentsInterface } from './types';

const defaultState: QualityDocumentsInterface = {
  data: [],
  hasMoreQualitydocuments: false,
};

export type QualityDocumentActions = ActionType<typeof qualityDocuments>;

export default (state = defaultState, action: QualityDocumentActions): QualityDocumentsInterface => {
  const documents: any = state.data;
  switch (action.type) {
    case getType(qualityDocuments.setQualityDocuments):
      return {
        data: action.payload.data,
        hasMoreQualitydocuments: action.payload.hasMoreQualitydocuments,
      };

    case getType(qualityDocuments.addQualityDocument):
      documents.unshift(action.payload.data);

      return {
        data: documents,
      };

      case getType(qualityDocuments.addQualityDocuments):
        const moreDocuments: any = [...documents, ...action.payload.data];

        return {
          data: moreDocuments,
          hasMoreQualitydocuments: action.payload.hasMoreQualitydocuments,
        };

    case getType(qualityDocuments.updateQualityDocument):
      const { data }: any = action.payload;
      const documentIndex = documents.findIndex((document: any) => data._id === document._id);

      documents[documentIndex] = {
        ...documents[documentIndex],
        ...data,
      };

      return {
        data: documents,
      };

    case getType(qualityDocuments.deleteQualityDocument):
      const documentId = action.payload;
      const updatedDocuments = documents.filter((document: any) => documentId !== document._id);

      return {
        data: updatedDocuments,
      };
    default:
      return state;
  }
};
