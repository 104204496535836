import React from "react";
import {
  IonCard,
  IonCardContent,
  IonText,
  IonIcon,
  IonRouterLink,
} from "@ionic/react";
import formatDate from "../../helpers/format-date";
import statusToColor from "../../helpers/status-to-color";
import { print } from "ionicons/icons";

export default (props: any) => {
  const { project, document } = props;
  const { icon, color } = statusToColor(document.qualitydocumentstatus.key);

  const iconMarkup =
    icon !== "" ? (
      <div className="ml-auto d-flex ion-align-items-center">
        <IonIcon className="card-icon" icon={icon} size="large" color={color} />
      </div>
    ) : undefined;

  const targetUrl = `/projects/${project.id}/qualitydocument/${document._id}`;

  return (
    <IonCard
      routerLink={targetUrl}
      className="ion-align-items-center d-flex card"
      onClick={(e) => {
        const target: any = e.target;
        if (target.dataset["print"] === "true") {
          e.preventDefault();
        }
      }}
    >
      <IonCardContent className="d-flex card-body">
        <div className="card-column">
          <h3 className="card-title text-truncate">{document.objectname}</h3>
          <div className="card-meta">
            <IonText color={color || "medium"}>
              {document.qualitydocumentstatus.text}
            </IonText>
            <IonText color="medium">{formatDate(document._timestamp)}</IonText>
          </div>
        </div>
        <div
          className="ml-auto"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {document.qualitydocumentstatus.key === "approved" ? (
            <IonRouterLink routerLink={`${targetUrl}?print=1`}>
              <IonIcon
                style={{ marginRight: "10px", marginTop: "5px" }}
                icon={print}
                color={"dark"}
                size="large"
                data-print="true"
              />
            </IonRouterLink>
          ) : undefined}
          {iconMarkup}
        </div>
      </IonCardContent>
    </IonCard>
  );
};
