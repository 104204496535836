import React from 'react';
import { currentUserIsAdmin } from '../../../helpers/roles';
import { IonGrid, IonRow, IonCol, IonIcon, IonText } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import UpdateStatusButton from './UpdateStatusButton';
import { currentUserIsTiler } from '../../../helpers/project';
import { connect } from 'react-redux';
import { RootState } from '../../../store';

class SectionSix extends React.Component<any> {
  render() {
    const status = this.props.currentQualityDocument.qualitydocumentstatus;

    let content;

    if (
      status === 'open' &&
      ! currentUserIsAdmin() &&
      currentUserIsTiler()
      ) {
        content =
          <>
            <UpdateStatusButton
              color="danger"
              label="Klar för slutgodkännande"
              updateValue="markasdone" />
            <div className="ion-padding-horizontal font-size-xs ion-text-center">
              Genom detta blir dokumentet klart för signering av Arbetsledare.
            </div>
          </>;
    } else if (currentUserIsAdmin()) {
      if (status === 'open') {
        content =
          <UpdateStatusButton
            color="success"
            label="Slutgodkänn kvalitetsdokumentet"
            updateValue="approved"
            openActionSheet={() => this.props.openActionSheet()} />;
      } else if (status === 'markasdone') {
        content =
        <>
          <IonIcon
            icon={checkmarkCircle}
            size="large"
            color="primary"
            className="d-block mx-auto"
            />
          <span className="d-block ion-margin-bottom">Detta kvalitetsdokument är markerad som <IonText color="primary">klart</IonText> av plattsättare.</span>
          <UpdateStatusButton
            color="danger"
            label="Godkänn inte"
            updateValue="open" />
          <UpdateStatusButton
            color="success"
            label="Slutgodkänn kvalitetsdokumentet"
            updateValue="approved"
            openActionSheet={() => this.props.openActionSheet()} />
        </>;
      }
    }

    if (content === undefined) {
      return <div />;
    }

    return (
      <>
        <IonGrid className="qty-document-footer ion-padding-top ion-padding-bottom">
          <IonRow className="ion-justify-content-center ion-text-center">
            <IonCol
              sizeSm="10"
              sizeMd="8"
              sizeLg="6"
              >
              { content }
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return ({ currentQualityDocument: state.currentQualityDocument });
};

export default connect(mapStateToProps)(SectionSix);
