import React from 'react';

import { IonMenuToggle, IonItem, IonLabel, IonIcon } from '@ionic/react';
import { NavLink } from 'react-router-dom';

export default (props: any) => {
  return (
    <IonMenuToggle autoHide={false}>
      <IonItem
        color="white"
        detail={false}
        >
        <NavLink replace to={props.url} className="menu-item-link">
          <IonIcon icon={props.icon} size="large" />
          <IonLabel className="font-size-sm">
            {props.title}
          </IonLabel>
        </NavLink>
      </IonItem>
    </IonMenuToggle>
  );
};
