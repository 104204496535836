import { createAction } from 'typesafe-actions';

export interface APIUserInterface {
  _embedded: {
    relation_company: {
      name: string,
      registrationno: string,
      qualificationno: string,
      wallzone1supplier_text: string,
      wallzone2supplier_text: string,
      floorsupplier_text: string,
      responsibilityinsurance_text: string,
    }
  };
  _id: number;
  company: number;
  companyName: string;
  companyNo: string;
  identityno: string;
  identityNoLong: string;
  name: string;
  position: { key: string };
  sessionToken: string;
  course1: string;
}

export const setUser = createAction('user/SET_USER', resolve =>
    (user: APIUserInterface) => resolve(user)
);

export const setCompanyDefaultStates = createAction('user/SET_COMPANY_DEFAULT_STATES', resolve =>
    (user: APIUserInterface) => resolve(user)
);
