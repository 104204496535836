import { legacy_createStore as createStore } from 'redux';

import rootReducer from './root-reducer';

// Use Redux DevTools extension compose if available, else fallback to Redux's compose
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__;

function configureStore(initialState?: {}) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers
  );
}

// pass an optional param to rehydrate state on app start
const store = configureStore();

// export store singleton instance
export default store;
