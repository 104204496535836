import React from 'react';
import { IonButtons, IonButton } from '@ionic/react';

class CreateProjectButton extends React.Component<any, any> {

  render() {
    return (
      <IonButtons slot="end">
        <IonButton onClick={this.props.handler} disabled={! this.props.settings.isValid ? true : false}>
          Skapa
        </IonButton>
      </IonButtons>
    );
  }
}

export default CreateProjectButton;
