import { loadingController, toastController } from "@ionic/core";
import {
  IonActionSheet,
  IonAlert,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonSpinner,
  withIonLifeCycle,
} from "@ionic/react";
import printJS from "print-js-updated";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LimeAPI from "../classes/LimeAPI";
import Header from "../components/Header/Header";
import SectionFive from "../components/QualityDocuments/detail/SectionFive";
import SectionFour from "../components/QualityDocuments/detail/SectionFour";
import SectionOne from "../components/QualityDocuments/detail/SectionOne";
import SectionSix from "../components/QualityDocuments/detail/SectionSix";
import SectionThree from "../components/QualityDocuments/detail/SectionThree";
import SectionTwo from "../components/QualityDocuments/detail/SectionTwo";
import QualityDocumentsHeader from "../components/QualityDocuments/QualityDocumentsHeader";
import { logEvent } from "../helpers/analytics";
import isBrowser from "../helpers/is-browser";
import store, { actions, RootState } from "../store";
import { QualityDocumentInterface } from "../store/currentQualityDocument/types";
import { QualityDocumentIdInterface } from "../store/qualityDocuments/types";

class PageQualityDocument extends React.Component<any, any> {
  abortController = new AbortController();

  constructor(props: any) {
    super(props);

    this.state = {
      isActionSheetOpen: false,
      isEmailActionSheetOpen: window.location.search.indexOf("print") > -1,
      isMailAlertOpen: false,
      isPrintAlertOpen: false,
      isDeleteAlertOpen: false,
      isFetching: true,
      isCopyAlertOpen: false
    };

    this.handleActionSheet = this.handleActionSheet.bind(this);
    this.handleEmailActionSheet = this.handleEmailActionSheet.bind(this);
    this.setShowMailAlert = this.setShowMailAlert.bind(this);
    this.setShowDeleteAlert = this.setShowDeleteAlert.bind(this);
    this.showEmailAlert = this.showEmailAlert.bind(this);
    this.editProject = this.editProject.bind(this);
    this.generatePdf = this.generatePdf.bind(this);
    this.printPdf = this.printPdf.bind(this);
    this.print = this.print.bind(this);
    this.deleteQualityDocument = this.deleteQualityDocument.bind(this);
    this.clearCurrentQualityDocument = this.clearCurrentQualityDocument.bind(
      this
    );
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.location.search !== this.props.location.search) {
      this.setState({
        isEmailActionSheetOpen: window.location.search.indexOf("print") > -1,
      });
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  handleActionSheet(open: boolean) {
    this.setState({ isActionSheetOpen: open });
  }

  handleEmailActionSheet(open: boolean) {
    this.setState({ isEmailActionSheetOpen: open });
  }

  ionViewWillEnter() {
    this.getCurrentQualityDocument();
  }

  getCurrentQualityDocument() {
    LimeAPI.get(
      `qualitydocuments/${this.props.match.params.documentId}`,
      this.abortController.signal
    )
      .then((document: any) => {
        this.props.setQualityDocument(document);
        this.setState({ isFetching: false });
      })
      .catch(() => false);
  }

  clearCurrentQualityDocument() {
    this.setState({ isFetching: true });
    this.props.clearQualityDocument({});
    this.props.history.push("/projects");
  }

  editProject() {
    const project = store.getState().currentProject;
    const qualityDocument = store.getState().currentQualityDocument;

    this.props.history.push(
      `/projects/${project.id}/qualitydocument/${qualityDocument._id}/edit`
    );
  }

  showEmailAlert() {
    this.setShowMailAlert(true);
  }

  async mailPdf(email: string) {
    const state = store.getState();
    const { currentQualityDocument } = state;
    const controller = await loadingController.create({
      spinner: "crescent",
      message: "Skickar pdf...",
    });

    await controller.present();

    const response = await this.generatePdfAndMail(
      email,
      currentQualityDocument
    );

    loadingController.dismiss().catch();

    if (!response.success) {
      const toast = await toastController.create({
        message: "Mejlet kunde inte skickas...",
        color: "dark",
        buttons: ["Ok"],
      });

      await toast.present();

      await logEvent("mailPdfToCustomer");

      return;
    }

    const toastSuccess = await toastController.create({
      message: "Pdf har skickats!",
      duration: 3000,
      color: "success",
    });

    await toastSuccess.present();
  }

  async generatePdfAndMail(email: string, currentQualityDocument: object) {
    return LimeAPI.post(
      `pdf`,
      { email, data: currentQualityDocument },
      this.abortController.signal
    );
  }

  async printPdf() {
    const state = store.getState();
    const { currentQualityDocument } = state;

    const controller = await loadingController.create({
      spinner: "crescent",
      message: "Genererar pdf...",
    });

    await controller.present();
    const response = await this.generatePdf(currentQualityDocument);

    loadingController.dismiss().catch();

    if (!response.success) {
      const toast = await toastController.create({
        message: "Filen kunde inte genereras...",
        color: "dark",
        buttons: ["Ok"],
      });

      await toast.present();

      return;
    }

    await logEvent("printPdf");

    if (isBrowser()) {
      printJS({ printable: response.data.file, type: "pdf", base64: true });
    } else {
      this.print(response.data.file);
    }

    const toastSuccess = await toastController.create({
      message: "Pdf har har skapats",
      duration: 3000,
      color: "success",
    });

    await toastSuccess.present();
  }

  print(pdf: string) {
    const options = {
      data: pdf,
    };

    window.plugins.PrintPDF.print(options);
  }

  async generatePdf(currentQualityDocument: object) {
    return LimeAPI.post(
      `printPdf`,
      { data: currentQualityDocument },
      this.abortController.signal
    );
  }

  setShowMailAlert(show: boolean) {
    this.setState({ isMailAlertOpen: show });
  }

  setShowDeleteAlert(show: boolean) {
    this.setState({ isDeleteAlertOpen: show });
  }

  deleteQualityDocument() {
    loadingController
      .create({
        spinner: "crescent",
        message: "Tar bort kvalitetsdokument...",
      })
      .then((controller) => controller.present())
      .catch();

    LimeAPI.delete(
      `qualitydocuments/${this.props.match.params.documentId}`,
      this.abortController.signal
    )
      .then((response: any) => {
        loadingController.dismiss().catch();

        if (!response.success) {
          toastController
            .create({
              message: "Kvalitetsdokumentet kunde inte tas bort.",
              color: "dark",
              buttons: ["Ok"],
            })
            .then((toast) => toast.present())
            .catch();
          return;
        }

        this.props.deleteQualityDocument(
          parseInt(this.props.match.params.documentId, 10)
        );
        toastController
          .create({
            message: `Kvalitetsdokumentet togs bort.`,
            duration: 3000,
            color: "success",
          })
          .then((toast) => toast.present())
          .catch();

        return this.props.history.goBack();
      })
      .catch(() => false);
  }

  copyQualityDocument(name: string) {
    if (!name) {
      toastController
        .create({
          message: "Kvalitetsdokumentet kunde inte kopieras. Ett objektsnamn för det nya kvalitetsdokumentet måste anges.",
          color: "dark",
          buttons: ["Ok"],
        })
        .then((toast) => toast.present())
        .catch();
      return
    }

    loadingController
      .create({
        spinner: "crescent",
        message: "Kopierar kvalitetsdokumentet...",
      })
      .then((controller) => controller.present())
      .catch();

      LimeAPI.post(
        'qualitydocuments/copy',
        {
          id: store.getState().currentQualityDocument._id,
          name
        },
        this.abortController.signal
      )
        .then((response: any) => {
          loadingController.dismiss().catch();

          if (!response.success) {
            toastController
              .create({
                message: "Kvalitetsdokumentet kunde inte kopieras.",
                color: "dark",
                buttons: ["Ok"],
              })
              .then((toast) => toast.present())
              .catch();

            return;
          }

          return this.props.history.goBack();
        })
        .catch(() => {
          loadingController.dismiss().catch();

          toastController
            .create({
              message: "Kvalitetsdokumentet kunde inte kopieras.",
              color: "dark",
              buttons: ["Ok"],
            })
            .then((toast) => toast.present())
            .catch();
        })
  }

  render() {
    const { qualityDocument } = this.props;
    const { isFetching } = this.state;

    const { currentQualityDocument } = store.getState();
    const color =
      currentQualityDocument.qualitydocumentstatus === "approved"
        ? "success"
        : "primary";
    const status = currentQualityDocument.qualitydocumentstatus;

    if (!qualityDocument) {
      return <div />;
    }

    const content = !isFetching ? (
      <>
        <QualityDocumentsHeader color={color} />

        <IonGrid className="ion-padding-top ion-padding-bottom">
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="10" sizeMd="8" sizeLg="6">
              <SectionOne />
              <SectionTwo />
              <SectionThree />
              <SectionFour />
              <SectionFive />
            </IonCol>
          </IonRow>
        </IonGrid>

        <SectionSix
          openActionSheet={() =>
            this.setState({ isEmailActionSheetOpen: true })
          }
        />

        <IonActionSheet
          isOpen={this.state.isEmailActionSheetOpen}
          onDidDismiss={() => this.handleEmailActionSheet(false)}
          buttons={[
            { text: "Skicka som e-post", handler: this.showEmailAlert },
            { text: "Skriv ut", handler: this.printPdf },
            { text: "Avbryt", role: "cancel" },
          ]}
        />

        <IonAlert
          isOpen={this.state.isDeleteAlertOpen}
          onDidDismiss={() => this.setShowDeleteAlert(false)}
          header={"Bekräfta"}
          message={
            "Vill du verkligen <br><strong><u>radera</u></strong> kvalitetsdokumentet? Om du gör det försvinner all sparad dokumentation"
          }
          buttons={[
            {
              text: "Avbryt",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Ja, ta bort",
              handler: () => {
                this.deleteQualityDocument();
              },
            },
          ]}
        />

        <IonAlert
          isOpen={this.state.isMailAlertOpen}
          onDidDismiss={() => this.setShowMailAlert(false)}
          header={"Skicka pdf"}
          buttons={[
            {
              text: "Avbryt",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Skicka",
              handler: (data: any) => {
                this.mailPdf(data.email_recipient).catch(() => false);
              },
            },
          ]}
          inputs={[
            {
              name: "email_recipient",
              type: "email",
              placeholder: "E-postadress",
            },
          ]}
        />

        <IonActionSheet
          isOpen={this.state.isActionSheetOpen}
          onDidDismiss={() => this.handleActionSheet(false)}
          buttons={[
            { text: "Redigera", handler: this.editProject },
            {
              text: "Kopiera",
              handler: () => this.setState({isCopyAlertOpen: true})
            },
            {
              text: "Radera",
              handler: () => {
                this.setShowDeleteAlert(true);
              },
              role: "destructive",
            },
            { text: "Avbryt", role: "cancel" },
          ]}
        />

        <IonAlert
          isOpen={this.state.isCopyAlertOpen}
          onDidDismiss={() => this.setState({isCopyAlertOpen: false})}
          header="Kopiera detta kvalitetsdokument?"
          message="Ange objekt för nytt kvalitetsdokumentet"
          buttons={[
            {
              text: "Avbryt",
              role: "cancel"
            },
            {
              text: "Kopiera",
              role: "selected",
              handler: ({name}) => this.copyQualityDocument(name)
            }
          ]}
          inputs={[
            {
              type: "text",
              name: "name",
              placeholder: "Ange objekt för nytt kvalitetsdokumentet",
            }
          ]}
        />
      </>
    ) : (
      <IonSpinner name="crescent" color="primary" />
    );

    return (
      <IonPage>
        <Header
          componentLeft="backToProjectsButton"
          componentLeftHandler={this.clearCurrentQualityDocument}
          title={!isFetching ? qualityDocument.objectname : ""}
          componentRight="qualityDocumentActions"
          componentRightHandler={
            status === "approved"
              ? this.handleEmailActionSheet
              : this.handleActionSheet
          }
          color={color}
        />
        <IonContent className="page-wrapper">{content}</IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    qualityDocument: state.currentQualityDocument,
  };
};

const mapDispatchToProps = {
  setQualityDocument: (qualityDocument: QualityDocumentInterface) =>
    actions.currentQualityDocument.setQualityDocument(qualityDocument),
  clearQualityDocument: () =>
    actions.currentQualityDocument.clearQualityDocument({}),
  deleteQualityDocument: (qualityDocumentId: QualityDocumentIdInterface) =>
    actions.qualityDocuments.deleteQualityDocument(qualityDocumentId),
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withIonLifeCycle(PageQualityDocument))
);

// const PageQualityDocumentWithLifeCycle = withIonLifeCycle(PageQualityDocument);
// const ConnectedPageQualityDocument = connect(mapStateToProps, mapDispatchToProps)(PageQualityDocumentWithLifeCycle);
// const PageQualityDocumentWithRouter = withRouter(ConnectedPageQualityDocument);

// export default PageQualityDocumentWithRouter;
