import React from "react";
import { IonIcon, IonButtons, IonButton } from "@ionic/react";
import { more, print } from "ionicons/icons";
import { qualityDocumentIsComplete } from "../../helpers/quality-document";

export default (props: any) => {
  return (
    <IonButtons slot="end">
      <IonButton onClick={() => props.handler(true)}>
        <IonIcon icon={qualityDocumentIsComplete() ? print : more} />
      </IonButton>
    </IonButtons>
  );
};
