import { alert, checkmarkCircle, warning } from 'ionicons/icons';

const statusMap: any = {
  open: {
    color: '',
    icon: '',
  },
  approved: {
    color: 'warning',
    icon: checkmarkCircle,
  },
  actionrequired: {
    color: 'warning',
    icon: alert,
  },
  markasdone: {
    color: 'warning',
    icon: alert,
  },
  delivered: {
    color: 'warning',
    icon: warning,
  },
  finished: {
    color: 'success',
    icon: checkmarkCircle,
  },
};

const defaultStatus = {
  color: '',
  icon: '',
}

export default (status: string) => statusMap[status] !== undefined ? statusMap[status] : defaultStatus;
