import React from 'react';

import { IonContent, IonPage } from '@ionic/react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import store, { RootState } from '../store/index';

import Header from '../components/Header/Header';
import EditProjectForm from '../components/EditProject/EditProjectForm';
import ProjectFooter from '../components/Project/ProjectFooter';

class PageEditProject extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isSubmitting: false,
      isValid: false,
      isDeleting: false,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.updateProject = this.updateProject.bind(this);
    this.disableSubmit = this.disableSubmit.bind(this);
    this.deleteProject = this.deleteProject.bind(this);
  }

  onChangeHandler(isValid: boolean) {
    this.setState({ isValid });
  }

  updateProject() {
    this.setState({ isSubmitting: true });
  }

  disableSubmit() {
    this.setState({ isSubmitting: false });
  }

  deleteProject(isDeleting: boolean) {
    this.setState({ isDeleting });
  }

  render() {
    const { project, user, history } = this.props;
    const { isAdmin } = store.getState().user;

    return (
      <IonPage>
        <Header
          componentLeft="back"
          title={project.name}
          componentRight="saveProject"
          componentRightHandler={this.updateProject}
          componentSettings={this.state}
        />
        <IonContent>
          <EditProjectForm
            project={this.props.project}
            isSubmitting={this.state.isSubmitting}
            onChangeHandler={this.onChangeHandler}
            user={user}
            history={history}
            disableSubmit={this.disableSubmit}
            isDeleting={this.state.isDeleting}
            setDeleteState={this.deleteProject}
          />
        </IonContent>

        {isAdmin && <ProjectFooter
                      updateProject={this.updateProject}
                      deleteProject={this.deleteProject} />}
      </IonPage>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    project: state.currentProject,
    user: state.user,
  };
};

export default withRouter(connect(mapStateToProps)(PageEditProject));

// const ConnectedPageEditProject = connect(mapStateToProps)(PageEditProject);
// const PageEditProjectWithRouter = withRouter(ConnectedPageEditProject);

// export default PageEditProjectWithRouter;
