export const newProjectState = {
  isValid: false,
  formData: {
    name: {
      required: true,
      value: '',
      isTouched: false,
    },
    supervisor: {
      required: true,
      value: [],
      isTouched: false,
    },
    tiler: {
      required: false,
      value: [],
      isTouched: false,
    },
  },
};

export const editProjectState = (name: string) => {
  return {
    isValid: false,
    formData: {
      name: {
        required: true,
        value: name,
        isTouched: false,
      },
      supervisor: {
        required: true,
        value: [],
        isTouched: false,
      },
      tiler: {
        required: false,
        value: [],
        isTouched: false,
      },
      prevParticipants: [],
      companyPersons: [],
    },
  };
};

export const newQualityDocumentState = () => {
  return {
    supplier: {},
    suppliersystemfloor: {},
    suppliersystemwallzone1: {},
    suppliersystemwallzone2: {},
    persontiler: '',
    personsupervisor: '',
    isValid: false,
    formData: {
      authorizationno: {
        required: true,
        value: '',
      },
      city: {
        required: true,
        value: '',
      },
      clientname: {
        required: true,
        value: '',
      },
      company: {
        required: true,
        value: 0,
      },
      companyname: {
        required: true,
        value: '',
      },
      copytoauthorizedcompany: {
        required: false,
        value: false,
      },
      floorfoundationok: {
        required: true,
        value: undefined,
      },
      floorslope: {
        required: false,
        value: false,
      },
      companysupplierfloor: {
        required: true,
        value: 0,
      },
      suppliersystemfloor: {
        required: true,
        value: 0,
      },
      newproduction: {
        required: true,
        value: false,
        relation: ['renovation'],
      },
      objectname: {
        required: true,
        value: '',
      },
      otherinformation: {
        required: true,
        value: '',
      },
      otherleadthrough: {
        required: true,
        value: undefined,
      },
      personsupervisor: {
        required: true,
        value: 0,
      },
      persontiler: {
        required: true,
        value: 0,
      },
      picture: {
        required: false,
        value: [],
      },
      project: {
        required: true,
        value: 0,
      },
      registrationno: {
        required: true,
        value: '',
      },
      renovation: {
        required: true,
        value: false,
        relation: ['newproduction'],
      },
      responsibilityinsurance: {
        required: true,
        value: '',
      },
      sealingworkok: {
        required: false,
        value: undefined,
      },
      supplementtoclient: {
        required: false,
        value: false,
      },
      wallfoundationok: {
        required: true,
        value: undefined,
      },
      companywallzone1supplier: {
        required: true,
        value: 0,
      },
      suppliersystemwallzone1: {
        required: true,
        value: 0,
      },
      companywallzone2supplier: {
        required: true,
        value: 0,
      },
      suppliersystemwallzone2: {
        required: true,
        value: 0,
      },
      wellbyothercontractor: {
        required: false,
        value: false,
      },
      wellfixedmount: {
        required: false,
        value: false,
      },
      welltypeapproved: {
        required: false,
        value: false,
      },
      wetroomamount: {
        required: true,
        value: '',
      },
      workfrom: {
        required: true,
        value: '',
      },
      workto: {
        required: true,
        value: '',
      },
      wetworkteamlead: {
        required: false,
        value: '',
      },
      tilername: {
        required: false,
        value: '',
      },
      floorsupplier: {
        required: false,
        value: '',
      },
      floorsystem: {
        required: false,
        value: '',
      },
      wallzone1supplier: {
        required: false,
        value: '',
      },
      wallzone1system: {
        required: false,
        value: '',
      },
      wallzone2supplier: {
        required: false,
        value: '',
      },
      wallzone2system: {
        required: false,
        value: '',
      },
      companywallzone1supplier_text: {
        required: false,
        value: '',
      },
      companywallzone2supplier_text: {
        required: false,
        value: '',
      },
      companysupplierfloor_text: {
        required: false,
        value: '',
      },
    },
  };
};
