import LimeAPI from './LimeAPI';
import store from '../store';
import { getType } from 'typesafe-actions';
import * as User from '../store/user/actions';

export default class UserSession {
  static abortController = new AbortController();

  static exists() {
    const authId = window.sessionStorage.getItem('authId');

    if (authId === '') {
      return false;
    }

    return true;
  }

  static async setUserData(props: any) {
    const authId = window.sessionStorage.getItem('authId');

    try {
      const response = await LimeAPI.post(
        `authenticateUser/getUserSession`,
        { sessionToken: authId },
        UserSession.abortController.signal
      );

      if (! response.success) {
        return response;
      }

      response.person.sessionToken = authId;

      store.dispatch({
        type: getType(User.setUser),
        payload: response.person,
      });

      return response;
    } catch (e) {
      console.log(e);
    }
  }
}
