import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import formatDate from "../../helpers/format-date";
import store from "../../store/index";
import QualityDocumentStatus from "./QualityDocumentStatus";

export default (props: any) => {
  const { currentProject, currentQualityDocument, user } = store.getState();
  const gridClasses = `page-header page-header-${props.color} ion-padding-top ion-padding-bottom`;

  const document = {
    name:
      currentQualityDocument.objectname !== ""
        ? currentQualityDocument.objectname
        : currentProject.name,
    createdTime:
      currentQualityDocument._createdtime !== ""
        ? currentQualityDocument._createdtime
        : currentProject.createdTime,
    updatedTime: currentProject.updatedTime,
  };

  return (
    <IonGrid className={gridClasses}>
      <IonRow className="ion-justify-content-center">
        <IonCol sizeSm="10" sizeMd="8" sizeLg="6">
          <QualityDocumentStatus hideStatus={props.hideStatus} />
          <h2>Kvalitetsdokument</h2>
          <h1>{document.name}</h1>

          <dl className="font-size-xs mt-0 ion-margin-bottom">
            <dt>{`Skapad: ${formatDate(document.createdTime)}`}</dt>
            <dt>{`Senast ändrad: ${formatDate(document.updatedTime)}`}</dt>
          </dl>

          <div className="object-meta">
            <span className="d-block font-size-xs">Behörig entreprenör</span>
            <span className="d-block">{user.company.name}</span>
          </div>

          <div className="object-meta">
            <span className="d-block font-size-xs">
              Byggkeramikrådets behörighetsnr
            </span>
            <span className="d-block">{user.company.qualificationNo}</span>
          </div>

          <div className="object-meta">
            <span className="d-block font-size-xs">Org.nummer</span>
            <span className="d-block">{user.company.orgNo}</span>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
