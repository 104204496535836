import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store/index';

import logout from '../../helpers/logout';

import {
  IonMenu,
  IonContent,
  IonList,
  IonMenuToggle,
  IonButton
} from '@ionic/react';

import { hammer, paper } from 'ionicons/icons';

import MenuHeader from './MenuHeader';
import MenuItem from './MenuItem';

import './menu.css';
import ContactInfo from '../ContactInfo/ContactInfo';

class Menu extends React.Component<any> {
  render() {
    const pages = [
      {
        title: 'Projekt',
        url: '/projects',
        icon: hammer,
      },
      {
        title: 'Dokument & information',
        url: '/information',
        icon: paper,
      },
    ];

    return (
      <IonMenu side="end" menuId="primary" contentId="primary" swipeGesture={true} type="overlay">
        <IonContent className="ion-padding" color="white">
          <div className="menu-inner d-flex">
            <MenuHeader userName={this.props.user.name || ''} companyName={this.props.user.company.name} />

            <IonList lines="none">
              {pages.map((page, index) => <MenuItem key={index} {...page} {...this.props} />)}
            </IonList>

            <ContactInfo />

            <IonMenuToggle className="mt-auto">
              <IonButton
                color="primary"
                onClick={() => logout() }
                expand="block"
                routerDirection="back"
                >
                Logga ut
              </IonButton>
            </IonMenuToggle>
          </div>
        </IonContent>
      </IonMenu>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { user: state.user };
};

export default connect(mapStateToProps)(Menu);
