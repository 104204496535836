import React from 'react';
import { IonButton, IonFooter, IonToolbar } from '@ionic/react';

class ProjectFooter extends React.Component<any> {
  constructor(props: any) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(Event: any) {
    const buttonType = Event.target.color;

    if (buttonType === 'success') {
      this.props.updateProject();
    } else if (buttonType === 'danger') {
      this.props.deleteProject(true);
    }
  }

  render() {
    return (
      <IonFooter color="white">
        <IonToolbar
          color="white"
          className="ion-text-center font-size-xs"
          >
          <div className="footer-content">
            <IonButton
              color="success"
              className="ion-margin-bottom"
              expand="block"
              onClick={this.onClick}
            >
              Spara projektet
            </IonButton>
            <IonButton
              color="danger"
              className="ion-margin-bottom"
              expand="block"
              onClick={this.onClick}
              >
              Radera projektet
            </IonButton>

            <p className="ion-margin-bottom">Genom att radera projektet så tar du bort det permanent och det kan ej återskapas.</p>
          </div>
        </IonToolbar>
      </IonFooter>
    );
  }
};

export default ProjectFooter;
