import React from 'react';
import { IonInput, IonLabel, IonSelect, IonSelectOption, IonGrid, IonRow, IonCol } from '@ionic/react';
import { showErrorMsg } from '../../helpers/validation';
import LimeAPI from '../../classes/LimeAPI';
import store from '../../store/index';
import { isAllowedRole } from '../../helpers/roles';

class CreateProjectForm extends React.Component<any, any> {
  abortController = new AbortController();

  constructor(props: any) {
    super(props);
    this.state = {
      supervisor: [],
      tiler: [],
      combo: [],
    };

    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    this.fetchCompanyPersons();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  addPersonToState(person: any, prop: string) {
    this.setState((prevState: any) => ({
      [prop]: [...prevState[prop], person],
    }));
  }

  async fetchCompanyPersons() {
    const response = await LimeAPI.get(`company/${store.getState().user.company.id}/person`, this.abortController.signal);

    if (response.data.length === 0) {
      return;
    }

    const persons = response.data;

    persons.forEach((person: any) => {
      const id = person._id;
      const { name } = person;

      if (isAllowedRole(person.position.key)) {
        this.addPersonToState({ id, name }, person.position.key);
      }
    });
  }

  showErrorMsg(name: string) {
    return showErrorMsg(name, this.props.projectState);
  }

  onInputChange(event: any) {
    this.props.saveState(event);
  }

  alphabeticSorting(persons : {id: string, name: string}[]) {
    persons.sort((a :{ id: string, name: string }, b : { id: string, name: string }) => a.name.localeCompare(b.name))
    return persons;
  }

  render() {
    const { formData } = this.props.projectState;

    return (
      <form>
        <IonGrid className="page-header ion-padding-top ion-padding-bottom">
          <IonRow className="ion-justify-content-center">
            <IonCol
              sizeSm="10"
              sizeMd="8"
              sizeLg="6"
              >
              <div className="form-group ion-padding mb-0">
                <IonLabel>Projektnamn</IonLabel>
                <IonInput
                  placeholder="Ex. Stadsgatan 14, 114 18 Stockholm"
                  name="name"
                  onIonChange={this.onInputChange}
                  required={formData.name.required}
                  value={formData.name.value}
                  />
                  <span>{this.showErrorMsg('name') ? 'Obligatoriskt fält' : ''}</span>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid className="page-content ion-padding-top ion-padding-bottom">
          <IonRow className="ion-justify-content-center">
            <IonCol
              sizeSm="10"
              sizeMd="8"
              sizeLg="6"
              >
              <div className="form-group ion-padding">
                <IonLabel>Arbetsledare</IonLabel>
                <IonSelect
                  name="supervisor"
                  multiple={true}
                  cancelText="Avbryt"
                  okText="Lägg till"
                  placeholder="Lägg till arbetsledare"
                  onIonChange={this.onInputChange}
                  >
                  {this.alphabeticSorting(([...this.state.supervisor, ...this.state.combo])).map((person: { id: string, name: string}) => {
                    return <IonSelectOption key={person.id} value={person.id}>{person.name}</IonSelectOption>;
                  })}
                </IonSelect>
                <span>{this.showErrorMsg('supervisor') ? 'Obligatoriskt fält' : ''}</span>
              </div>

              <div className="form-group ion-padding">
                <IonLabel>Plattsättare</IonLabel>
                <IonSelect
                  name="tiler"
                  multiple={true}
                  cancelText="Avbryt"
                  okText="Lägg till"
                  placeholder="Lägg till plattsättare"
                  onIonChange={this.onInputChange}
                >
                  {this.alphabeticSorting(([...this.state.tiler, ...this.state.combo])).map((person: { id: string, name: string}) => {
                    return <IonSelectOption key={person.id} value={person.id}>{person.name}</IonSelectOption>;
                  })}
                </IonSelect>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    );
  }
}

export default CreateProjectForm;
